import React, {useContext, useState} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";

//the page to add a recipient
//typeNotification = recipient
function AddTypeNotification(props) {

    const setAddRecipient = props.setAddRecipient;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the regex formula to validate the phone number
    const phoneRegex = /\d{10,12}/;

    //the initial values for formik
    const initialValues = {
        name: "",
        description: "",
        phone: "",
        email: "",
    };

    //the yup validation schema with the obligation to enter a phone or a mail
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        phone: Yup.string().when("email", {
            is: (email) => email === undefined,
            then: () => Yup.string().matches(phoneRegex, "Phone number is not valid").required("Phone or email required"),
            otherwise: () => Yup.string().matches(phoneRegex, {message: "Phone number is not valid", excludeEmptyString: true}),
        }),
        email: Yup.string().when("phone", {
            is: (phone) => phone === undefined,
            then: () => Yup.string().required("Phone or email required"),
            otherwise: () => Yup.string().notRequired()
        }),
    }, [["phone", "email"]]); // <-- prevent the loop

    //method to add a type notification
    const submitMethod = (value, {resetForm}) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("addTypeNotification");

        //format the phone and the mail
        let phone = null;
        let email = null;
        if(value.phone !== ""){
            phone = value.phone;
        }
        if(value.email !== ""){
            email = value.email;
        }

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameTypeNotif":"' +
            value.name + '", "descrTypeNotif":"' + value.description + '", "phone":"' + phone + '", "email":"' + email + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addTypeNotification");
        //publish a demand to add a typeNotification
        mqttRequests.publish(mqttClient, "alarm/addTypeNotification", data);

        operation = mqttRequests.generateOperationCode("getTypeNotification");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getTypeNotification");
        mqttRequests.publish(mqttClient, "alarm/getTypeNotification", data);

        resetForm({});
    };

    return(
        <div className="alarmAdd">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <FormikControl control="input" type="text" label="Phone: " name="phone"/>
                            <p className="alarmTextHint">If your phone is: +41 79 123 45 67 -> you write: 41791234567</p>
                            <FormikControl control="input" type="email" label="Email: " name="email"/>
                            <button type="submit" className="alarmButton">Validate</button>
                            <button type="button" className="alarmButton" onClick={() => setAddRecipient(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AddTypeNotification;
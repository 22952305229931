import React, {useContext, useEffect, useState} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {UserInfoContext} from "../../context/UserInfoContext";

//the page to edit a recipient group
//groupNotification = recipient group
function EditGroupNotification() {

    //the recipient group to edit
    let groupNotification = JSON.parse(localStorage.getItem("groupNotificationEdit"));

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        name: groupNotification.nameGroupNotif,
        description: groupNotification.descrGroupNotif,
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
    });

    //method to edit the recipient group
    const submitMethod = (value) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("editGroupNotification");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "idGroupNotification":"' + groupNotification.idGroupNotification
            + '", "oldNameGroupNotif":"' + groupNotification.nameGroupNotif + '", "newNameGroupNotif":"' + value.name + '", "descrGroupNotif":"' + value.description + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editGroupNotification");
        //publish a demand to edit a recipient group.
        mqttRequests.publish(mqttClient, "alarm/editGroupNotification", data);

        //to get the recipient groups
        operation = mqttRequests.generateOperationCode("getGroupNotification");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
        mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="alarmButton">Validate</button>
                            <button type="button" className="alarmButton">Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default EditGroupNotification;
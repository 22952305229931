import React, {useContext} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";

//the page to edit some user
function EditUser(props) {

    const setEditUser = props.setEditUser;

    //variables to display the popups
    const username = localStorage.getItem("username");

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        newUsername: username,
        newFirstname: localStorage.getItem("firstname"),
        newLastname: localStorage.getItem("lastname"),
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        newUsername: Yup.string().required("Required"),
        newFirstname: Yup.string().required("Required"),
        newLastname: Yup.string().required("Required"),
    });

    //method to communicate with mqtt
    const submitMethod = (value) => {

        //generate operation code
        let operation = mqttRequests.generateOperationCode("editUser");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "username":"' + username +
            '", "newUsername":"' + value.newUsername + '", "newFirstName":"' + value.newFirstname + '", "newLastName":"' + value.newLastname + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editUser");

        //publish a demand to edit a user
        mqttRequests.publish(mqttClient, "user/editUser", data);

        operation = mqttRequests.generateOperationCode("getUsers");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getUsers");
        mqttRequests.publish(mqttClient, "user/getUsers", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="username: " name="newUsername"/>
                            <FormikControl control="input" type="text" label="firstname: " name="newFirstname"/>
                            <FormikControl control="input" type="text" label="lastname: " name="newLastname"/>
                            <button type="submit" className="UserButton">Validate</button>
                            <button type="button" className="UserButton" onClick={() => setEditUser('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default EditUser;
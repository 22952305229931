import Router from "./components/Router";
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

//render the Router
function App() {
  return (
    <div>
        <Router/>
    </div>
  );
}

export default App;
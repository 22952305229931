import React, {useContext, useEffect, useState} from 'react';
import './Mission.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {UserInfoContext} from "../../context/UserInfoContext";

//the page to edit a mission
function EditMission(props) {

    let setEditMission = props.setEditMission;
    let idUser = props.idUser;

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/missions")
        }, false);
    }, [navigate]);

    //variables to display the popups
    const[errMessage, setErrMessage] = useState(false);

    //the mission to edit
    const mission = JSON.parse(localStorage.getItem("editMission"));

    //checkbox to set mission start and stop date manually or automatic
    const [startDateCheckboxManually, setStartDateCheckboxManually] = useState(mission.dateStart === 0);
    const [stopDateCheckboxManually, setStopDateCheckboxManually] = useState(mission.dateStop === 0);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        nameMission: mission.nameMission,
        descriptionMission: mission.descrMission,
        dateStart: mission.dateStart === 0 ? moment(new Date()).format('YYYY-MM-DD') : moment(new Date(mission.dateStart*1000)).format('YYYY-MM-DD'),
        dateStop: mission.dateStop === 0 ? moment(new Date()).format('YYYY-MM-DD') : moment(new Date(mission.dateStop*1000)).format('YYYY-MM-DD'),
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        nameMission: Yup.string().required("Required"),
        dateStart: Yup.date().required("Required"),
        dateStop: Yup.date().required("Required")
    });

    //method to edit a mission
    const submitMethod = (value) => {
        //check if the beginning date is before the end date
        if(new Date(value.dateStop)-new Date(value.dateStart) < 0){
            setErrMessage(true)
        } else {
            //generate operation code
            let operation = mqttRequests.generateOperationCode("editMission");

            //get the timestamp for the date at 0h00 in the time zone of the creation
            let startDate = new Date(value.dateStart).getTime() / 1000 + new Date().getTimezoneOffset() * 60;
            let stopDate = new Date(value.dateStop).getTime() / 1000 + new Date().getTimezoneOffset() * 60;

            if(startDateCheckboxManually){
                startDate = 0;
            }
            if (stopDateCheckboxManually){
                stopDate = 0;
            }

            let lastCheck = Math.round(new Date().getTime() / 1000 + new Date().getTimezoneOffset() * 60);

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "idMission":"'
                + mission.idMission + '", "oldNameMission":"' + mission.nameMission + '", "newNameMission":"' + value.nameMission + '", "descrMission":"' + value.descriptionMission
                + '", "dateStart":"' + startDate + '", "dateStop":"' + stopDate + '", "stateMission":"' + mission.stateMission + '", "lastCheck":"' + lastCheck + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/editMission");

            //publish a demand to edit the mission
            mqttRequests.publish(mqttClient, "mission/editMission", data);

            operation = mqttRequests.generateOperationCode("getMissions");

            //create json to publish
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '"}';

            //publish a demand to get all the missions
            mqttRequests.publish(mqttClient, "mission/getMissions", data);
        }
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            {mission.stateMission === 1 ?
                                <FormikControl control="input" type="text" label="New name: " name="nameMission"/>
                                :
                                null
                            }
                            <FormikControl control="textarea" type="text" label="Description: " name="descriptionMission"/>
                            {mission.stateMission === 1 ?
                                <div className="missionFlexboxDivManagement">

                                    <input className="missionCheckbox" type="checkbox" id="startDate" name="startDate"
                                           onClick={() => setStartDateCheckboxManually(!startDateCheckboxManually)} checked={startDateCheckboxManually} readOnly="yes"/>
                                    <p className="missionFlexText">Manage start date Manually</p>
                                </div>
                                :
                                null
                            }
                            {startDateCheckboxManually === false && mission.stateMission === 1 ?
                                <FormikControl control="input" type="date" label="Start date : " name="dateStart" />
                                :
                                null
                            }

                            <div className="missionFlexboxDivManagement">

                                <input className="missionCheckbox" type="checkbox" id="stopDate" name="stopDate"
                                       onClick={() => setStopDateCheckboxManually(!stopDateCheckboxManually)} checked={stopDateCheckboxManually} readOnly="yes"/>
                                <p className="missionFlexText">Manage stop date Manually</p>
                            </div>
                            {stopDateCheckboxManually === false ?
                                <FormikControl control="input" type="date" label="Stop date : " name="dateStop"/>
                                :
                                null
                            }
                            <button type="submit" className="missionButton">Validate</button>
                            <button type="button" className="missionButton" onClick={() => setEditMission('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
            <Snackbar open={errMessage} autoHideDuration={4000} onClose={() => setErrMessage(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - end date before begin date</Alert>
            </Snackbar>
        </div>
    )
}

export default EditMission;
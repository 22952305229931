import React, {useContext, useEffect, useState} from 'react';
import './Kinetiks.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import {Alert, Dialog, DialogActions, DialogTitle, Snackbar} from "@mui/material";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import EditKinetic from "./EditKinetic";

//the page witch display all the kinetics from an account
function GetKinetics(props) {

    //remove the color on the menu
    let setMenuClicked = props.setMenuClicked;
    setMenuClicked('getKinetics');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idAccount, idRight, idUser} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //set variable to get the data
    const [kinetics, setKinetics] = useState([]);
    //set variable to display an error message if something goes wrong
    const [unknownError, setUnknownError] = useState(false);
    //set variable to re get the data
    const [reshow, setReshow] = useState(false);
    //set variable for the dialog (message "are you sure to hide")
    const[dialogOpen, setDialogOpen] = useState(false);
    //set variable to store the name
    const [kineticName, setKineticName] = useState("");
    //set variable for the action Hide/show
    const [action, setAction] = useState("");

    const [editKinetic,setEditKinetic] = useState('');

    //variables to display the popups
    const [kineticsEdited, setKineticsEdited] = useState(false);
    const[errOneOpen, setErrOneOpen] = useState(false);
    const[errTwoOpen, setErrTwoOpen] = useState(false);
    const[errThreeOpen, setErrThreeOpen] = useState(false);

    //if the user have rights
    const [kineticsRight, setKineticsRight] = useState(true);

    //determines if the user have rights to edit/hide kinetics
    useEffect(() => {
        if(idUser !== ""){
            if(idRight !== null){
                try{
                    setKineticsRight(idRight.includes("02112221623298373534"));
                } catch (error) {
                    console.error(error);
                    setKineticsRight(false);
                }
            }
        }
    }, [idRight, idUser]);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getKinetic");

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getKinetic");

            //publish a demand to get all the kinetics
            mqttRequests.publish(mqttClient, "kinetic/getKinetic", data);

            //read incoming message and send response to a function to refactor them
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //if it has kinetics, it's to load data
                if(jsonParsed.kinetics){
                    if(jsonParsed.result===1){
                        setKinetics(jsonParsed.kinetics);
                    }else{
                        setUnknownError(true);
                    }
                //else, it's to hide/show the kinetic
                }else if(jsonParsed.operation.includes("editKinetic")){
                    switch (jsonParsed.result){
                        case 1:
                            localStorage.setItem("kineticEdited", "true");
                            setKineticsEdited(true);
                            setEditKinetic('');
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        case -3:
                            //display error popup
                            setErrThreeOpen(true);
                            break;
                        default :
                            break;
                    }
                }
            })
        }
    },[source, idAccount, mqttClient, reshow]);

    //open the dialog "Are you sure you want to do that ?"
    const handleClickHideActive = (action, name) => {
        //set the action and the username
        setAction(action);
        setKineticName(name);

        //display the dialog
        setDialogOpen(true);
    }

    //manage the hide, show, activate and deactivate a kinetic
    const hideActive = () => {
        //close the confirmation dialog
        setDialogOpen(false);

        //correct the action to use the correct name to interact with mqtt (subscribe and post in the right channel)
        let realAction = action + "Kinetic";

        //generate operation code
        const operation = mqttRequests.generateOperationCode(realAction);

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameKinetics":"' + kineticName + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/" + realAction);

        //publish a demand to hide/show kinetic
        mqttRequests.publish(mqttClient, "kinetic/" + realAction, data);

        //recharge data
        setReshow(!reshow);
    }

    //go to the edit kinetic page
    const handleEdit = (kinetic) => {
        //set the user infos to recover it in the edit page
        localStorage.setItem("kineticEdit", JSON.stringify(kinetic));
        setEditKinetic(kinetic.nameKinetics);
    }

    //select which data must be rendered depending on the id asked
    const getParameterKinetic = (kinetic, id, type) => {
        let response = "";
        kinetic.kinParameterKinetics.forEach((element) =>{
            if(element.idKinParameter === id){
                if(type === "string"){
                    response = element.valueString;
                }
                if(type === "int"){
                    response = element.valueInteger;
                }
                if(type === "float"){
                    response = element.valueFloat;
                }
            }
        })
        return response;
    }

    //translate the type kinetic from number to words
    const convertTypeKinetics = (type) => {
        if(type === 4){
            return "Sparse data";
        }else if(type === 0){
            return "Isoconvertional";
        }else if(type === 3){
            return "Model f(a)";
        }else {
            return type;
        }
    }

    return(
        <div>

            <h2 className="baseTitle">All Kinetics</h2>
            {unknownError?
                <p>Sorry, an error has occurred</p>
                :

                <div className="baseDivAccordion">
                    {kinetics.length === 0 ?
                        <p>There are currently no kinetics linked to this account, you can use the "Thermokinetiks" software to add them.</p>
                        :
                        null
                    }
                    <Accordion alwaysOpen>
                        {kinetics.sort((a,b) => a.nameKinetics > b.nameKinetics ? 1 : -1,).map(function (kinetic, id) {
                            return (
                                <div key={id}>
                                    <Accordion.Item eventKey={id}>
                                        <Accordion.Header>{kinetic.nameKinetics}</Accordion.Header>
                                        <Accordion.Body className="baseAccordionBody">
                                            <table className="baseTableNoBorder">
                                                <tbody>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Name:</td>
                                                    <td className="baseTd">{kinetic.nameKinetics}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Description:</td>
                                                    <td className="baseTd">{kinetic.descrKinetics !== "" ? kinetic.descrKinetics : "-"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Type:</td>
                                                    <td className="baseTd">{convertTypeKinetics(kinetic.typeKinetics)}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Creation date:</td>
                                                    <td className="baseTd">{moment(kinetic.dateCreation*1000).format("DD MMMM YYYY")}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Hide:</td>
                                                    <td className="baseTd">{kinetic.hideKinetics === 0 ? "No" : "Yes"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Created by:</td>
                                                    <td className="baseTd">{kinetic.userLoginName}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Warning limit:</td>
                                                    <td className="baseTd">
                                                        {getParameterKinetic(kinetic, "20230210153551170002", "string") !== "" ?
                                                            getParameterKinetic(kinetic, "20230210153551170002", "string") + ' (' + getParameterKinetic(kinetic,"20230210153643834021", "string") + ')'
                                                            :
                                                            "none"}
                                                    </td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">Critical limit:</td>
                                                    <td className="baseTd">
                                                        {getParameterKinetic(kinetic, "20230210153546656001", "string") !== "" ?
                                                            getParameterKinetic(kinetic, "20230210153546656001", "string") + ' (' + getParameterKinetic(kinetic,"20230210153643834021", "string") + ')'
                                                            :
                                                            "none"}
                                                    </td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">With humidity:</td>
                                                    <td className="baseTd">{getParameterKinetic(kinetic,"20230210153632437015", "int") === "0" ? "No" : "Yes"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="kineticsBoldSpan">With bootstrap:</td>
                                                    <td className="baseTd">{getParameterKinetic(kinetic,"20230210153657899026", "int") === "0" ? "No" : "Yes"}</td>
                                                </tr>





                                                <tr>
                                                    <td className="kineticsBoldSpan"></td>
                                                    <td>
                                                        {kineticsRight ?
                                                            <>
                                                                <button className="kineticsButton" onClick={() => handleEdit(kinetic)}>Edit</button>
                                                                {kinetic.hideKinetics===0?
                                                                    <button className="kineticsButton" onClick={() => handleClickHideActive("hide", kinetic.nameKinetics)}>Hide kinetics</button>
                                                                    :
                                                                    <button className="kineticsButton" onClick={() => handleClickHideActive("show", kinetic.nameKinetics)}>Show kinetics</button>
                                                                }
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {editKinetic === kinetic.nameKinetics ?
                                                <div className="kineticsEdit">
                                                    <EditKinetic setEditKinetic={setEditKinetic}/>
                                                </div>
                                                :
                                                null
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                            )
                        })}
                    </Accordion>
                </div>

            }
            <Snackbar open={kineticsEdited} autoHideDuration={4000} onClose={() => setKineticsEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Kinetic successfully edited</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Kinetic name not found</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={errThreeOpen} autoHideDuration={4000} onClose={() => setErrThreeOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - new name already exists</Alert>
            </Snackbar>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure to " + action + " this kinetic ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="kineticsButton" onClick={() => setDialogOpen(false)}>No</button>
                    <button className="kineticsButton" onClick={hideActive}>Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GetKinetics;
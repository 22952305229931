import React from "react";
import {Field, ErrorMessage} from "formik";
import TextError from "./TextErrorComponent";

//The component Formik for dropdown lists
function Select (props) {
    const { label, name, options, ...rest } = props;

    return (
        <div className="formControl">
            <label htmlFor={name} className="label">{label}</label>
            <Field as="select" id={name} name={name} {...rest} className="selectField">
                {
                    options.map(option => {
                        return <option
                            key={option.value} value={option.value}>{option.key}
                        </option>
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError}/>
        </div>
    )
}
export default Select;

import React, {useContext, useEffect, useState} from 'react';
import "./Settings.css"
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {SettingsContext} from "../context/SettingsContext";
import {useNavigate} from "react-router-dom";

//page for the user settings
function Settings(props) {

    //remove the color on the menu
    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('settings');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idUser and idAccount for mqtt
    const {temperatureUnit} = useContext(SettingsContext);

    //the method to update the context
    const setTemperatureUnit = props.setTemperatureUnit;

    //state to the actual state of the radio button
    const [unit, setUnit] = useState(temperatureUnit);

    //when a change occurs in the radio button
    const handleChange = (event) => {
        setUnit(event.target.value);
        setTemperatureUnit(event.target.value);
        localStorage.setItem("temperatureUnit", event.target.value);
    }

    return (
        <div>
            <h2 className="baseTitle">Settings</h2>
            <p className="settingsText"></p>
            <FormControl>
                <FormLabel className="settingsText">Temperature unit: </FormLabel>
                <RadioGroup
                    className="settingsText"
                    name="temperature_setting"
                    value={unit}
                    onChange={handleChange}
                    row
                >
                    <FormControlLabel value="C" control={<Radio sx={{'&.Mui-checked': {color:'#000'}}}/>} label="Degree Celsius"/>
                    <FormControlLabel value="F" control={<Radio sx={{'&.Mui-checked': {color:'#000'}}}/>} label="Degree Fahrenheit"/>
                    <FormControlLabel value="K" control={<Radio sx={{'&.Mui-checked': {color:'#000'}}}/>} label="Kelvin"/>
                </RadioGroup>
            </FormControl>
        </div>
    );
}

export default Settings;

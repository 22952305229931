import React, {useCallback} from 'react'
import {Field, ErrorMessage, useFormikContext} from "formik";
import TextError from "./TextErrorComponent";

function RadioButton(props) {
    const { label, name, options, onChangeAction, ...rest } = props;
    const { setFieldValue } = useFormikContext();

    const handleChange = useCallback(
        (event) => {
        onChangeAction(event.target.value);
        setFieldValue(name, event.target.value);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setFieldValue]
    );

    return (
        <div className="formControl">
            <label className="label">{label}</label>
            <Field name={name} {...rest}>
                {
                    ({field}) => {
                        return options.map(option => {
                            return(
                                <React.Fragment key={options.key}>
                                    <input type="radio" id={option.value} {...field} value={option.value} checked={field.value === option.value} onChange={handleChange} className="checkBox"/>
                                    <label htmlFor={option.value}>{option.key}</label>
                                </React.Fragment>
                            )
                        })
                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default RadioButton;
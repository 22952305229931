import React, {useContext, useState} from 'react';
import './Devices.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import FormikControl from "../FormTemplate/FormikControl";
import {Alert, Snackbar} from "@mui/material";
import mqttRequests from "../../Utils/mqttRequests";

//the page to add some profiles
function AddProfile(props) {

    const setProfileAdd = props.setProfileAdd;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //variable to open the popup
    const [errUndefinedOpen, setErrUndefinedOpen] = useState(false);

    //to read a file
    const [file, setFile] = useState();

    //the initial values for formik
    const initialValues = {
        name: "",
        description: "",
        time: "1",
        temperature: "C",
        humidity: "%",
        header: "",
        file: "",
        startDate: "2022-01-01",
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        startDate: Yup.date().required("required"),
    });

    //options for the dropdown list
    const timeOptions = [
        {key:"seconds", value: 1},
        {key:"minutes", value: 60},
        {key:"hours", value: 3600},
        {key:"days", value: 86400},
    ]

    //options for the dropdown list
    const temperatureOptions = [
        {key:"°C", value: "C"},
        {key:"°F", value: "F"},
        {key:"K", value: "K"},
    ]

    //options for the dropdown list
    const humidityOptions = [
        {key:"%", value: "%"}
    ]

    //options for the dropdown list
    const headerOptions = [
        {key: "", value: "header"},
    ];

    //method to add a profile
    const submitMethod = (value, {resetForm}) => {
        //read the file
        if(file === undefined){
            setErrUndefinedOpen(true);
        }else {
            let rows;
            //if it contains a header
            if(value.header.length === 0){
                rows = file.slice(0);
            }else {
                rows = file.slice(1);
            }
            //create the tables depending on the temperature
            if(value.temperature === "F"){
                rows = rows.map((row) => {
                    row[0] = row[0]*parseInt(value.time);
                    row[1] = parseFloat(Math.round(((row[1]-32)*5/9)*100)/100);
                    row[2] = parseFloat(row[2]);
                    return row;
                })
            }else if(value.temperature === "K"){
                rows = rows.map((row) => {
                    row[0] = row[0]*parseInt(value.time);
                    row[1] = parseFloat(Math.round((row[1]-273.15)*100)/100);
                    row[2] = parseFloat(row[2]);
                    return row;
                })
            }else {
                rows = rows.map((row) => {
                    row[0] = row[0]*parseInt(value.time);
                    row[1] = parseFloat(row[1]);
                    row[2] = parseFloat(row[2]);
                    return row;
                })
            }
            //set the datatype
            let datatype = ['20221222161657076001', '20221222161803478003'];

            //get the timestamp for the start of the day of the date selected
            let startDate = new Date(value.startDate).getTime()/1000;

            //send to the server
            //generate operation code
            let operation = mqttRequests.generateOperationCode("addProfile");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameProfile":"' +
                value.name + '", "descrProfile":"' + value.description + '", "fileData":"' + JSON.stringify(rows) + '", "datatype":"[' + datatype + ']", "startDate":"' + startDate + '"}';
            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/addProfile");
            //publish a demand to add a profile
            mqttRequests.publish(mqttClient, "profile/addProfile", data);

            operation = mqttRequests.generateOperationCode("getProfile");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getProfile");
            mqttRequests.publish(mqttClient, "profile/getProfile", data);

            resetForm({});
            setProfileAdd(false);
        }
    };

    //watch if the form change and prepare the variables to read the file
    const handleOnChange = async (e) => {
        if(e.target.id === "file"){
            if(e.target.files){
                try{
                    const file = e.target.files[0];

                    const fileUrl = URL.createObjectURL(file);

                    const response = await fetch(fileUrl);

                    const text = await response.text();

                    const lines = text.split("\n");

                    const _data = lines.map((line)=> line.split(" "));

                    setFile(_data);

                } catch(error){
                    console.error(error);
                }
            }
        }
    }

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form onChange={handleOnChange}>
                            <FormikControl control="input" type="text" label="Name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <FormikControl control="input" type="file" label="File: " name="file" accept={".csv, .txt"}/>
                            <FormikControl control="select" label="Format time: " name="time" options={timeOptions}/>
                            <FormikControl control="select" label="Format temperature: " name="temperature" options={temperatureOptions}/>
                            <FormikControl control="select" label="Format humidity: " name="humidity" options={humidityOptions}/>
                            <FormikControl control="checkbox" label="Has a header: " name="header" options={headerOptions} style={{width: 20, height:20}}/>
                            <FormikControl control="input" type="date" label="Date of first point: " name="startDate"/>
                            <button type="submit" className="devicesButton">Validate</button>
                            <button type="button" className="devicesButton" onClick={() => setProfileAdd(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
            <Snackbar open={errUndefinedOpen} autoHideDuration={4000} onClose={() => setErrUndefinedOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - No file selected</Alert>
            </Snackbar>
        </div>
    )
}

export default AddProfile;
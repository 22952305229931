import React, {useContext, useEffect, useState} from 'react';
import './Menu.scss'
import AKTS_logo from '../assets/AKTS_logo.png';
import MenuButton from '../assets/Home.png';
import {useNavigate} from "react-router-dom";
import {UserInfoContext} from "../context/UserInfoContext";
import mqttRequests from "./mqttRequests";
import {MqttClientContext} from "../context/MqttClientContext";

//page for the left menu
function Menu(props) {

    //setters to update some variables
    const clicked = props.menuClicked;
    const setClicked = props.setMenuClicked;
    const setIdUser = props.setIdUser;
    const setIdAccount = props.setIdAccount;
    const setIdRight = props.setIdRight;
    const refreshSource = props.refreshSource;
    const setRefreshSource = props.setRefreshSource;

    let navigate = useNavigate();

    //the contexts
    const {idUser, idRight} = useContext(UserInfoContext);

    //context for mqtt
    const {mqttClient, source} = useContext(MqttClientContext)

    //variable to manage the display if the user have the rights
    const [UserManagementRight, setUserManagementRight] = useState(false);

    //connect to the client when the page opens
    useEffect(() => {
        if(idUser !== ""){
            if(idRight !== null){
                try{
                    setUserManagementRight(idRight.includes("02112221623298373532"));
                } catch (error) {
                    console.error(error);
                    setUserManagementRight(false);
                }
            }
        }
    }, [idRight, idUser]);

    //get the rights at each refresh
    useEffect(() => {
        if(mqttClient !== null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getRightsFromUser");

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getRightsFromUser");

            //publish a demand to get all the user groups
            mqttRequests.publish(mqttClient, "user/getRightsFromUser", data);

            //read incoming message and send response to a function to refactor them
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                if(jsonParsed.idRight){
                    setIdRight(jsonParsed.idRight);
                    localStorage.setItem("idRight", JSON.stringify(jsonParsed.idRight));
                }
            })
        }
    }, [mqttClient, idUser, setIdRight, source]);

    const clickHome = () => {
        navigate("/");
        setClicked("home");
    }

    const clickNavigate = (destination) => {
        setClicked(destination);
        navigate("/" + destination);
    }

    const clickLogout = () => {
        mqttRequests.unSubscribe(mqttClient, "device/list/" + localStorage.getItem("idAccount"));
        localStorage.setItem("idUser", "");
        localStorage.setItem("idAccount", "");
        localStorage.setItem("idRight", "{}");
        setIdUser("");
        setIdAccount("");
        setIdRight("");
        setClicked("Home");
        setRefreshSource(!refreshSource);
        navigate("/");
    }

    return(
        <>
            <div className="MenuMainDiv">
                <img src={AKTS_logo} alt="AKTS logo" className="MenuImg" onClick={clickHome}/>
                <div className="MenuText">
                    <p onClick={clickHome}>Home</p>
                </div>
                {idUser !== ""?
                    <div>
                        <div className="MenuText">
                            <p style={{"color": clicked==="missions"? "#009fe3" : "white"}} onClick={() => clickNavigate("missions")}>Missions</p>
                            <p style={{"color": clicked==="deviceList"? "#009fe3" : "white"}} onClick={() => clickNavigate("deviceList")}>Devices</p>
                            <p style={{"color": clicked==="getGroupDevice"? "#009fe3" : "white"}} onClick={() => clickNavigate("getGroupDevice")}>Device Group</p>
                            <p style={{"color": clicked==="getKinetics"? "#009fe3" : "white"}} onClick={() => clickNavigate("getKinetics")}>Kinetic Library</p>
                        </div>
                        <h2 className="MenuSubtitle">ALARMS</h2>
                        <div className="MenuText">
                            <p style={{"color": clicked==="supervision"? "#009fe3" : "white"}} onClick={() => clickNavigate("supervision")}>Supervisions</p>
                            <p style={{"color": clicked==="alarms"? "#009fe3" : "white"}} onClick={() => clickNavigate("alarms")}>Alarms History</p>
                            <p style={{"color": clicked==="recipient"? "#009fe3" : "white"}} onClick={() => clickNavigate("recipient")}>Alarm Recipients</p>
                        </div>
                        <h2 className="MenuSubtitle">MANAGEMENT</h2>
                        {UserManagementRight?
                            <div className="MenuText">
                                <p style={{"color": clicked==="userManagement"? "#009fe3" : "white"}} onClick={() => clickNavigate("userManagement")}>User Management</p>
                                <p style={{"color": clicked==="groupManagement"? "#009fe3" : "white"}} onClick={() => clickNavigate("groupManagement")}>Group Management</p>
                                <p style={{"color": clicked==="settings"? "#009fe3" : "white"}} onClick={() => clickNavigate("settings")}>Settings</p>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                <div id="MenuLogin" className="MenuText">
                    {idUser === "" ?
                        <p style={{"color": clicked==="login"? "#009fe3" : "white"}} onClick={() => clickNavigate("login")}>Login</p>
                        :
                        <p onClick={clickLogout}>Logout</p>
                    }
                </div>
            </div>
            <div className="mobileMenu">
                <img src={MenuButton} alt="AKTS logo" className="Menubutton" onClick={clickHome}/>
            </div>
        </>
    )
}

export default Menu;
import React, {useContext, useEffect, useState} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import {SettingsContext} from "../../context/SettingsContext";

//the page to get all supervisions sorted by missions
function GetAllSupervisions(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('supervision');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
        }, false);
    }, [navigate]);

    //the idUser and idAccount for mqtt
    const {idUser, idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the temperature unit from the context
    const {temperatureUnit} = useContext(SettingsContext);

    //variables to set the supervisions
    const [missionSupervisions, setMissionSupervisions] = useState([]);
    const [missionSupervisionDisplay, setMissionSupervisionDisplay] = useState([]);

    //variable to get the missions, the devices, the kinetics ,the groupNotifications and the datatype
    const [mission, setMission] = useState([])
    const [devices, setDevices] = useState([]);
    const [kinetics, setKinetics] = useState([]);
    const [groupNotification, setGroupNotification] = useState([]);
    const [dataType, setDataType] = useState([]);

    //method to get all things needed from mqtt
    useEffect(() => {
        if(mqttClient !== null){

            //read incoming message and set the mission variable
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //if it's a response for getMission
                if(jsonParsed.mission && jsonParsed.result === 1){
                    setMission(jsonParsed.mission);
                //if it's a response for getDevices
                }else if(jsonParsed.devices) {
                    setDevices(jsonParsed.devices);
                //if it's a response for get kinetics
                }else if(jsonParsed.kinetics){
                    setKinetics(jsonParsed.kinetics);
                //if it's a response for get missionSupervision
                }else if (jsonParsed.missionSupervision) {
                    setMissionSupervisions(jsonParsed.missionSupervision);
                //if it's a response for get groupNotification
                } else if(jsonParsed.groupNotification){
                    setGroupNotification(jsonParsed.groupNotification);
                //if it's a response for get datatype
                }else if (jsonParsed.idDataType){
                    refactorDataType(jsonParsed);
                }
            });

            //demand to get missions
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getMissions");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '"}';
            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getMissions");
            //publish a demand to get all the missions
            mqttRequests.publish(mqttClient, "mission/getMissions", data);

            //demand to get the devices
            operation = mqttRequests.generateOperationCode("getAllDevices");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //demand to get the kinetics
            operation = mqttRequests.generateOperationCode("getKinetic");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getKinetic");
            mqttRequests.publish(mqttClient, "kinetic/getKinetic", data);

            //demand to get mission supervisions
            operation = mqttRequests.generateOperationCode("getAllMissionSupervisions");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllMissionSupervisions");
            mqttRequests.publish(mqttClient, "alarm/getAllMissionSupervisions", data);

            //demand to get groupNotifications
            operation = mqttRequests.generateOperationCode("getGroupNotification");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
            mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);

            //demand to get datatype
            operation = mqttRequests.generateOperationCode("getDataType");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getDataType");
            mqttRequests.publish(mqttClient, "device/getDataType", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, idUser, mqttClient]);

    //get the missionSupervision in an array, get one array for each mission
    useEffect(() => {
        //get all the supervisons in an array
        if(mission.length !== 0 && missionSupervisions.length !== 0) {
            let tempMission = missionSupervisions.map((supervision) => {
                let tempMissionSupervision = mission.map((mission)=> {
                    if(mission.idMission === supervision.idMission){
                        if(mission.stateMission === 1 || mission.stateMission === 2){
                            return {
                                idDevice: supervision.idDevice,
                                mission: mission.nameMission,
                                actif : supervision.actif,
                                idDatatype: supervision.idDatatype,
                                idGroupNotification: supervision.idGroupNotification,
                                idKinetics: supervision.idKinetics,
                                limite: supervision.limite,
                                limiteType: supervision.limiteType,
                                severity: supervision.severity,
                                gatewayIdentifier: supervision.gatewayIdentifier,
                            }
                        }
                    }
                    return undefined;
                })
                //get only the entry with the notification
                tempMissionSupervision = tempMissionSupervision.filter((e)=>{return e !== undefined});
                return tempMissionSupervision[0];
            });
            //sort the mission by alphabetical order of name and remove the undefined values
            tempMission = tempMission.sort((a,b) => a.mission > b.mission ? 1 : -1).filter((e)=>{return e !== undefined});

            //create an array of array, each mission have an array with all its notifications
            let tempSortedSupervision = [];
            let actualMissionName = tempMission[0].mission;
            let tempSupervisonForMission = [];
            tempMission.forEach((mission) => {
                if(mission.mission === actualMissionName){
                    tempSupervisonForMission.push(mission);
                }else {
                    tempSortedSupervision.push(tempSupervisonForMission);
                    tempSupervisonForMission = [mission];
                    actualMissionName = mission.mission;
                }
            })
            tempSortedSupervision.push(tempSupervisonForMission);

            //set the variable for the display
            setMissionSupervisionDisplay(tempSortedSupervision);
        }

    }, [mission, missionSupervisions])

    //refactor for the datatype
    const refactorDataType = (data) => {
        setDataType(data.idDataType.map((idDataType, index) =>{
            return {
                id: idDataType,
                name : data.description[index]
            }
        }))
    }

    //function to send the name of the kinetic depending on the id to display
    const displayKineticsName = (id) => {
        let nameToDisplay = "";
        kinetics.forEach((element) => {
            if(element.idKinetics === id){
                nameToDisplay = element.nameKinetics;
            }
        });
        return nameToDisplay;
    }

    //function to send the name of the device depending on the id to display
    const displayNameDevice = (id) => {
        let identifier = null;
        let name = null ;
        devices.forEach((element) => {
            if(element.idDevice === id){
                identifier = element.identifier;
                name = element.customName;
            }
        })
        return name + ' (identifier: ' + identifier + ')';
    }

    //function to send the name of the group notification depending on the id to display
    const getGroupNotifNameFromId = (id) => {
        let name = "";
        groupNotification.forEach((element) => {
            if(element.idGroupNotification === id){
                name = element.nameGroupNotif;
            }
        })
        return name ;
    }

    //create the text to display after the "when" (the datatype, the limitType and the limit)
    //gi = gateway identifier
    const DefineWhenAlarm = (idDataType, limiteType, limit, gi) => {
        if(idDataType === '20230126103350301008'){
            return "there is a crash"
        }else {
            let name = "";
            dataType.forEach((element) => {
                if(element.id === idDataType){
                    name = element.name;
                }
            })

            //special case of gateway identifier
            if(gi === "external"){
                name = name + " external"
            }else if(gi === "mv"){
                name = name + " mV"
            }else if(gi === "gateway" && idDataType === "20230126103350301001"){
                name = name + " %"
            }

            //display the temperature corresponding to the unit defined in the settings
            if(name === "Temperature" && (limiteType === 1 || limiteType === 2)){
                let limitCorrectUnit = limit;
                if(temperatureUnit === "F"){
                    limitCorrectUnit = Math.round(((limit*9/5)+32) *100)/100;
                }
                if(temperatureUnit === "K"){
                    limitCorrectUnit = Math.round((limit+273.15) *100)/100;
                }

                //set if it's larger or lower
                if(limiteType === 1) {
                    return name + " is larger than " + limitCorrectUnit;
                }else if (limiteType === 2) {
                    return name + " is lower than " + limitCorrectUnit;
                }
            }else{
                //set if it's larger, lower or if it's an alarm on the silence time
                if(limiteType === 1) {
                    return name + " is larger than " + limit;
                }else if (limiteType === 2){
                    return name + " is lower than " + limit;
                }else {
                    return "The device do not send points during " + limit + " minutes";
                }
            }
        }
    }

    return(
        <div>
            <h2 className="baseTitle">Supervisions:</h2>
            {missionSupervisionDisplay.length!==0?
                missionSupervisionDisplay.map(function (groupSupervision, id) {
                    return (
                        <div key={id}>
                            <h3 className="alarmSubTitle">Mission: {groupSupervision[0].mission}</h3>
                            <div className="alarmDivFlexMarginLeft">
                                <table className="alarmTable">
                                    <thead>
                                    <tr>
                                        <th className="alarmTableText">On device</th>
                                        <th className="alarmTableText">device connected</th>
                                        <th className="alarmTableText">Kinetics</th>
                                        <th className="alarmTableText">Notification</th>
                                        <th className="alarmTableText">Trigger</th>
                                        <th className="alarmTableText">Active</th>
                                    </tr>
                                    </thead>
                                    {groupSupervision.map((supervision, subId) => {
                                        return (
                                            <tbody key={subId}>
                                                <tr className="alarmDivTxt">
                                                    <td>
                                                        <p className="alarmTableTextBold">{displayNameDevice(supervision.idDevice)}</p>
                                                    </td>
                                                    <td>
                                                        {supervision.gatewayIdentifier === "mv" || supervision.gatewayIdentifier === "external" || supervision.gatewayIdentifier === "gateway"?
                                                            <p className="alarmTableText">The gateway</p>
                                                            :
                                                            supervision.gatewayIdentifier !== ""?
                                                                <p className="alarmTableText">device connected: {supervision.gatewayIdentifier}</p>
                                                                :
                                                                <p className="alarmTableText">-</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        {supervision.idKinetics !== ""?
                                                            <p className="alarmTableText">kinetic: {displayKineticsName(supervision.idKinetics)}</p>
                                                            :
                                                            <p className="alarmTableText">-</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        <p className="alarmTableText">{supervision.severity === 1 ? "warning" : "critical"}, to {getGroupNotifNameFromId(supervision.idGroupNotification)}</p>
                                                    </td>
                                                    <td>
                                                        <p className="alarmTableText">{DefineWhenAlarm(supervision.idDatatype, supervision.limiteType, supervision.limite, supervision.gatewayIdentifier)}</p>
                                                    </td>
                                                    <td>
                                                        <p className="alarmTableText">Active: {supervision.actif === 1 ? "Yes" : "No"}</p>
                                                    </td>
                                            </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>
                        </div>
                    )
                })
                :
                <p className="alarmText">No supervision assigned missions planned or actives</p>
            }
        </div>
    )
}

export default GetAllSupervisions;
import React, {useContext} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {UserInfoContext} from "../../context/UserInfoContext";

//the page to edit a recipient
//typeNotification = recipient
function EditTypeNotification(props) {

    const setEditTypeNotif = props.setEditTypeNotif;

    //the recipient to edit
    let typeNotification = JSON.parse(localStorage.getItem("typeNotificationEdit"));

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the regex formula to validate the phone number
    const phoneRegex = /\d{10,12}/;

    //the initial values for formik
    const initialValues = {
        name: typeNotification.nameTypeNotif,
        description: typeNotification.descrTypeNotif,
        phone: typeNotification.phone === "null"? "" : typeNotification.phone,
        email: typeNotification.email === "null"? "" : typeNotification.email,
    };

    //the yup validation schema
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        phone: Yup.string().when("email", {
            is: (email) => email === undefined,
            then: () => Yup.string().matches(phoneRegex, "Phone number is not valid").required("Phone or email required"),
            otherwise: () => Yup.string().notRequired()
        }),
        email: Yup.string().when("phone", {
            is: (phone) => phone === undefined,
            then: () => Yup.string().required("Phone or email required"),
            otherwise: () => Yup.string().notRequired()
        }),
    }, [["phone", "email"]]); // <-- table to prevent the loop error

    //method to edit the recipient
    const submitMethod = (value) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("editTypeNotification");

        //format the phone and email
        let phone = null;
        let email = null;
        if(value.phone !== ""){
            phone = value.phone;
        }
        if(value.email !== ""){
            email = value.email;
        }

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "idTypeNotification":"' + typeNotification.idTypeNotification + '", "oldNameTypeNotif":"' +
            typeNotification.nameTypeNotif + '", "newNameTypeNotif":"' + value.name + '", "descrTypeNotif":"' + value.description + '", "phone":"' + phone + '", "email":"' + email + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editTypeNotification");
        //publish a demand to edit the recipient
        mqttRequests.publish(mqttClient, "alarm/editTypeNotification", data);

        //get type operation
        operation = mqttRequests.generateOperationCode("getTypeNotification");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getTypeNotification");
        mqttRequests.publish(mqttClient, "alarm/getTypeNotification", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <FormikControl control="input" type="text" label="Phone: " name="phone"/>
                            <p className="alarmTextHint">If your phone is: +41 79 123 45 67 -> you write: 41791234567</p>
                            <FormikControl control="input" type="email" label="Email: " name="email"/>
                            <button type="submit" className="alarmButton">Validate</button>
                            <button type="button" className="alarmButton" onClick={() => setEditTypeNotif('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>

        </div>
    )
}

export default EditTypeNotification;
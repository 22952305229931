import React from "react";
import {Field, ErrorMessage} from "formik";
import TextError from "./TextErrorComponent";

//The component Formik for checkbox text field
function Checkbox (props) {
    const { label, name, options, display, style, ...rest } = props;

    return (
        <div className="formControl">
            <label className="label">{label}</label>
            <Field id={name} name={name} {...rest} className="field">
                {({ field }) => {
                    return options.map((option, index) => {
                        return (
                            <React.Fragment key={option.key}>
                                {index<12?
                                    (
                                        <>
                                            <br/>
                                            <input
                                                type='checkbox'
                                                id={option.value}
                                                {...field}
                                                value={option.value}
                                                checked={field.value.includes(option.value)}
                                                style={style}
                                                className="checkBox"
                                            />
                                            <label htmlFor={option.value}>{option.key}</label>
                                        </>
                                    )
                                :
                                    null
                                }
                            </React.Fragment>
                        )
                    })
                }}
            </Field>
            {display === "true" ? <ErrorMessage name={name} component={TextError}/> : null}
        </div>
    )
}
export default Checkbox;

import React, {useContext, useEffect, useState} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import {Alert, Dialog, DialogActions, DialogTitle, Snackbar} from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import EditTypeNotification from "./EditTypeNotification";
import AddTypeNotification from "./AddTypeNotification";
import EditGroupNotification from "./EditGroupNotification";
import AddGroupNotification from "./AddGroupNotification";
import ManageGroupNotification from "./ManageGroupNotification";

//the page witch display all the recipients and recipient groups from an account
function TypeNotification(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('typeNotification');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //set variable to get the data
    const [typeNotif, setTypeNotif] = useState([]);
    const [groupNotif, setGroupNotif] = useState([]);
    const [groupTypeNotification, setGroupTypeNotification] = useState([]);
    //set variable to display an error message if something goes wrong
    const [unknownError, setUnknownError] = useState(false);
    //set variable to re get the data
    const [reshow, setReshow] = useState(false);

    //variable to open the dialog to delete type and groups
    const [dialogTypeOpen, setDialogTypeOpen] = useState(false);
    const [dialogGroupOpen, setDialogGroupOpen] = useState(false);
    const [errOneOpen, setErrOneOpen] = useState(false);
    const [idDelete, setIdDelete] = useState("");

    //set variable to display the popups
    const [typeNotifEdited, setTypeNotifEdited] = useState(false);
    const [groupNotifEdited, setGroupNotifEdited] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [successOpenDeleteGroup, setSuccessOpenDeleteGroup] = useState(false);
    const [errTwoOpen, setErrTwoOpen] = useState(false);
    const [errThreeOpen, setErrThreeOpen] = useState(false);
    const [successAdd ,setSuccessAdd] = useState(false);
    const [successGroupAdd, setSuccessGroupAdd] = useState(false);
    const [successManagementOpen,setSuccessManagementOpen] = useState(false);

    const [editTypeNotif, setEditTypeNotif] = useState('');
    const [addRecipient, setAddRecipient] = useState(false);
    const [addGroupRecipient, setAddGroupRecipient] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getTypeNotification");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getTypeNotification");
            //publish a demand to get all the recipients
            mqttRequests.publish(mqttClient, "alarm/getTypeNotification", data);

            //to get the recipient groups
            operation = mqttRequests.generateOperationCode("getGroupNotification");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
            mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);

            //read incoming message
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //load the data for the recipients
                if(jsonParsed.typeNotification){
                    if(jsonParsed.result===1){
                        setTypeNotif(jsonParsed.typeNotification);
                    }else{
                        setUnknownError(true);
                    }
                //load the data for the recipient groups
                }else if(jsonParsed.groupTypeNotification) {
                    if (jsonParsed.result === 1) {
                        //keep only the typeNotification in the group
                        let tempGroupTypeNotif = jsonParsed.groupTypeNotification.map((element) => {
                            return element.idTypeNotification;
                        })
                        setGroupTypeNotification(tempGroupTypeNotif);
                    }
                }else if(jsonParsed.groupNotification) {
                    if(jsonParsed.result===1){
                        setGroupNotif(jsonParsed.groupNotification);
                    }else {
                        setUnknownError(true);
                    }
                //for popups
                }else if(jsonParsed.operation.includes("editTypeNotification")){
                    if(jsonParsed.result === 1){
                        setTypeNotifEdited(true);
                    }else if(jsonParsed.result === -1) {
                        setErrOneOpen(true);
                    }else{
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("addTypeNotification")){
                    if(jsonParsed.result === 1){
                        setSuccessAdd(true);
                        setAddRecipient(false);
                    }else if(jsonParsed.result === -1) {
                        setErrOneOpen(true);
                    }else{
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("addGroupNotification")){
                    if(jsonParsed.result === 1){
                        setSuccessGroupAdd(true);
                        setAddGroupRecipient(false);
                    }else if(jsonParsed.result === -1) {
                        setErrOneOpen(true);
                    }else{
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("editGroupNotification")){
                    if(jsonParsed.result === 1){
                        setGroupNotifEdited(true);
                    }else if(jsonParsed.result === -1) {
                        setErrOneOpen(true);
                    }else{
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("addGroupTypeNotification")){
                    if(jsonParsed.result === 1){
                        setSuccessManagementOpen(true);
                    }else{
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("deleteGroupNotification")){
                    if(jsonParsed.result === 1){
                        setSuccessOpenDeleteGroup(true);
                    }else if(jsonParsed.result === -2){
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.operation.includes("deleteTypeNotification")){
                    if(jsonParsed.result === 1){
                        setSuccessOpen(true);
                    }else if(jsonParsed.result === -2){
                        setErrTwoOpen(true);
                    }else if(jsonParsed.result === -3) {
                        setErrThreeOpen(true);
                    }
                }
            });
        }
    },[source, idAccount, mqttClient, reshow]);

    //go to the edit recipient page
    const handleEdit = (typeNotification) => {
        //set the user infos to recover it in the edit page
        localStorage.setItem("typeNotificationEdit", JSON.stringify(typeNotification));
        setEditTypeNotif(typeNotification.nameTypeNotif);
    }

    //open the dialog to delete a recipient
    const handleDelete = (id) => {
        setDialogTypeOpen(true);
        setIdDelete(id);
    }

    //real delete for the recipient
    const realDelete = () => {
        //close the dialog
        setDialogTypeOpen(false);

        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteTypeNotification");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idTypeNotification":"' + idDelete + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteTypeNotification");
        //publish a demand to delete a recipient
        mqttRequests.publish(mqttClient, "alarm/deleteTypeNotification", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
    }

    //open the edit options
    const handleGroupEdit = (groupNotification) => {
        //set the user infos to recover it in the edit page
        localStorage.setItem("groupNotificationEdit", JSON.stringify(groupNotification));
        setEditTypeNotif('group' + groupNotification.nameGroupNotif);
    }

    //open the dialog to delete a group
    const handleGroupDelete = (id) => {
        setDialogGroupOpen(true);
        setIdDelete(id);
    }

    //real delete for a group
    const realGroupDelete = () => {
        //close the dialog
        setDialogGroupOpen(false);

        //generate operation code
        const operation = mqttRequests.generateOperationCode("deleteGroupNotification");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idGroupNotification":"' + idDelete + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteGroupNotification");
        //publish a demand to delete a group
        mqttRequests.publish(mqttClient, "alarm/deleteGroupNotification", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
    }

    //go to manage notifications
    const handleManageNotification = (group) => {
        //set the group id to recover it in the manage page
        localStorage.setItem("groupNotificationManage", JSON.stringify(group));
        setEditTypeNotif('manage' + group.nameGroupNotif)
    }

    return(
        <div className="baseMainDiv">

            <h2 className="baseTitle">All Alarm recipients</h2>
            {unknownError?
                <p>Sorry, an error has occurred</p>
                :
                <div className="alarmDivFlex">
                    <div className="alarmHalfBody">
                        <h3 className="alarmSubTitle">Recipients</h3>
                        <div className="alarmDivAccordion">
                            <Accordion alwaysOpen>
                                {typeNotif.sort((a,b) => a.nameTypeNotif > b.nameTypeNotif ? 1 : -1,).map(function (typeNotification, id) {
                                    return (
                                        <div key={id}>
                                            <Accordion.Item eventKey={id}>
                                                <Accordion.Header>{typeNotification.nameTypeNotif}</Accordion.Header>
                                                <Accordion.Body className="baseAccordionBody">
                                                    <table className="baseTableNoBorder">
                                                        <tbody>
                                                        <tr className="baseTr">
                                                            <td className="alarmBoldSpan">Name:</td>
                                                            <td className="baseTd">{typeNotification.nameTypeNotif}</td>
                                                        </tr>
                                                        <tr className="baseTr">
                                                            <td className="alarmBoldSpan">Description:</td>
                                                            <td className="baseTd">{typeNotification.descrTypeNotif !== "" ? typeNotification.descrTypeNotif : "-"}</td>
                                                        </tr>
                                                        <tr className="baseTr">
                                                            <td className="alarmBoldSpan">Phone:</td>
                                                            <td className="baseTd">{typeNotification.phone !== "null"? typeNotification.phone : "-"}</td>
                                                        </tr>
                                                        <tr className="baseTr">
                                                            <td className="alarmBoldSpan">Mail:</td>
                                                            <td className="baseTd">{typeNotification.email !== "null"? typeNotification.email : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="alarmBoldSpan"></td>
                                                            <td>
                                                                <button className="alarmButton" onClick={() => handleEdit(typeNotification)}>Edit</button>
                                                                <button className="alarmButtonDelete" onClick={() => handleDelete(typeNotification.idTypeNotification)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    {editTypeNotif === typeNotification.nameTypeNotif ?
                                                        <div className="alarmEdit">
                                                            <EditTypeNotification setEditTypeNotif={setEditTypeNotif}/>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    )
                                })}
                            </Accordion>
                            <button className="alarmButton" onClick={() => setAddRecipient(true)}>Add recipient</button>
                            {addRecipient ?
                                <AddTypeNotification setAddRecipient={setAddRecipient}/>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="alarmHalfBody">
                        <h3 className="alarmSubTitle">Groups</h3>
                        <div className="alarmDivAccordion">
                            <Accordion alwaysOpen>
                                {groupNotif.sort((a,b) => a.nameGroupNotif > b.nameGroupNotif ? 1 : -1,).map(function (groupNotification, id) {
                                    return (
                                        <div key={id}>
                                            <Accordion.Item eventKey={id}>
                                                <Accordion.Header>{groupNotification.nameGroupNotif}</Accordion.Header>
                                                <Accordion.Body className="baseAccordionBody">
                                                    <table className="baseTableNoBorder">
                                                        <tbody>
                                                        <tr className="baseTr">
                                                            <td className="alarmBoldSpan">Name:</td>
                                                            <td className="baseTd">{groupNotification.nameGroupNotif}</td>
                                                        </tr>
                                                        <tr className="baseTr">
                                                            <td className="alarmBoldSpan">Description:</td>
                                                            <td className="baseTd">{groupNotification.descrGroupNotif !== "" ? groupNotification.descrGroupNotif : "-"}</td>
                                                        </tr>
                                                        {groupNotification.typeNotifInside.length !== 0 ?
                                                            groupNotification.typeNotifInside.sort((a,b) => a > b ? 1 : -1,).map((element, subId) => {
                                                                return(
                                                                    <tr key={subId}>
                                                                        {subId === 0 ?
                                                                            <td className="deviceBoldSpan">Recipients:</td>
                                                                            :
                                                                            <td className="deviceBoldSpan"></td>
                                                                        }
                                                                        <td className="baseTd">- {element}</td>
                                                                    </tr>
                                                                )})
                                                            :
                                                            <tr>
                                                                <td className="deviceBoldSpan">Recipients:</td>
                                                                <td className="baseTd">-</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className="alarmBoldSpan"></td>
                                                            <td>
                                                                <button className="alarmButton" onClick={() => handleGroupEdit(groupNotification)}>Edit</button>
                                                                <button className="alarmButtonManage" onClick={() => handleManageNotification(groupNotification)}>Manage recipients</button>
                                                                <button className="alarmButtonDelete" onClick={() => handleGroupDelete(groupNotification.idGroupNotification)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    {editTypeNotif === 'group' + groupNotification.nameGroupNotif ?
                                                        <div className="alarmEdit">
                                                            <EditGroupNotification setEditTypeNotif={setEditTypeNotif}/>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {editTypeNotif === 'manage' + groupNotification.nameGroupNotif ?
                                                        <div className="alarmEdit">
                                                            <ManageGroupNotification setEditTypeNotif={setEditTypeNotif} typeNotification={typeNotif} groupTypeNotification={groupTypeNotification}/>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    )
                                })}
                            </Accordion>
                            <button className="alarmButton" onClick={() => setAddGroupRecipient(true)}>Add group</button>
                            {addGroupRecipient ?
                                <AddGroupNotification setAddGroupRecipient={setAddGroupRecipient}/>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            }
            <Snackbar open={typeNotifEdited} autoHideDuration={4000} onClose={() => setTypeNotifEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Recipient successfully edited</Alert>
            </Snackbar>
            <Snackbar open={groupNotifEdited} autoHideDuration={4000} onClose={() => setGroupNotifEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Recipient group successfully edited</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Recipient successfully deleted</Alert>
            </Snackbar>
            <Snackbar open={successOpenDeleteGroup} autoHideDuration={4000} onClose={() => setSuccessOpenDeleteGroup(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Recipient group successfully deleted</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={errThreeOpen} autoHideDuration={4000} onClose={() => setErrThreeOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - This group is used in an alarm, you can not delete it</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Name already exists</Alert>
            </Snackbar>
            <Snackbar open={successAdd} autoHideDuration={4000} onClose={() => setSuccessAdd(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Recipient successfully added</Alert>
            </Snackbar>

            <Snackbar open={successGroupAdd} autoHideDuration={4000} onClose={() => setSuccessGroupAdd(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Group successfully added</Alert>
            </Snackbar>
            <Snackbar open={successManagementOpen} autoHideDuration={4000} onClose={() => setSuccessManagementOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Recipient(s) successfully added at group</Alert>
            </Snackbar>
            <Dialog
                open={dialogTypeOpen}
                onClose={() => setDialogTypeOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure you want to delete this recipient ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="alarmButton" onClick={() => setDialogTypeOpen(false)}>No</button>
                    <button className="alarmButton" onClick={realDelete}>Yes</button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogGroupOpen}
                onClose={() => setDialogGroupOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure you want to delete this recipient group ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="alarmButton" onClick={() => setDialogGroupOpen(false)}>No</button>
                    <button className="alarmButton" onClick={realGroupDelete}>Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TypeNotification;
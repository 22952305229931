import React, {useContext, useEffect, useState} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {
    Alert,
    Checkbox, checkboxClasses,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar
} from "@mui/material";
import deleteButton from "../../assets/delete.png";

//the page witch display all the rights for a user groups
function GetGroupRight(props) {

    const setEditGroup = props.setEditGroup;
    const allRights = props.allRights;
    const rightsInGroup = props.rightsInGroup;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    const nameGroupUser = localStorage.getItem("groupUserName");

    //variable to stock rights not in the group (names)
    const [rightsNotInGroup, setRightsNotInGroup]= useState(props.rightsNotInGroup);
    //variable to stock rights to add in the group (names)
    const [rightsToAdd, setRightsToAdd]= useState([]);
    //set variable to re get the data
    const [reshow, setReshow] = useState(false);
    //variable to determine if the form is visible or not
    const [formVisible, setFormVisible] = useState(false);

    //variables to display the popups
    const[warnEmptyOpen, setWarnEmptyOpen] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operationGroupRights = mqttRequests.generateOperationCode("getGroupRight");

            //create json to publish
            const dataGroupRights = '{"operation":"' + operationGroupRights + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupUser":"' + nameGroupUser + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupRight");

            //publish a demand to get all the rights for a user groups
            mqttRequests.publish(mqttClient, "user/getGroupRight", dataGroupRights);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, reshow]);



    //manage data and display the form (or a warning popup if nothing to add)
    const handleDisplayForm = () => {
        console.log(rightsNotInGroup)
        console.log(allRights)
        //create a variable with all the names of the rights based on their id (rightsInGroup have ids, allRights have id + name)
        let nameForId = rightsInGroup.map((element) => {
            let tempObject = allRights.find(obj => {
                return obj.id === element
            });
            return tempObject.name;
        })

        //remove rights already in group from the list to add
        let tempRights = rightsNotInGroup.filter( (element) => !nameForId.includes(element));
        setRightsNotInGroup(tempRights);

        //if no one left to add show the warning, else, show the form
        if(tempRights.length === 0){
            setWarnEmptyOpen(true);
        }else {
            setFormVisible(true);
        }
    }

    //manage the changes in the form
    const handleChange = (event) => {
        const {
            target : {value},
        } = event;
        setRightsToAdd(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    //add the rights to the group
    const handleAddRights = () => {

        if(rightsToAdd.length!==0){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("addGroupUserRight");

            //create a variable with all the names of the rights based on their id (rightsInGroup have ids, allRights have id + name)
            let idForName = rightsToAdd.map((element) => {
                let tempObject = allRights.find(obj => {
                    return obj.name === element
                });
                return tempObject.id;
            })

            //format data for json
            let rightToInsert = idForName.map(element => '"' + element + '"');

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idRight":[' + rightToInsert + '], "idAccount":"' +
                idAccount + '", "nameGroupUser":"' + nameGroupUser + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupUserRight");

            //publish a demand to add a user
            mqttRequests.publish(mqttClient, "user/addGroupUserRight", data);

            //hide the form and empty it
            setFormVisible(false);
            setRightsToAdd([]);

            //get the groups users again
            operation = mqttRequests.generateOperationCode("getGroupUser");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");
            mqttRequests.publish(mqttClient, "user/getGroupUser", data);

            //reshow data
            setReshow(!reshow);
        }
    }

    //remove a user from a group
    const handleDelete = (right) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("removeGroupUserRight");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupUser":"' +
            nameGroupUser + '", "idRight":"' + right + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/removeGroupUserRight");

        //publish a demand to add a user
        mqttRequests.publish(mqttClient, "user/removeGroupUserRight", data);

        //get the groups users again
        operation = mqttRequests.generateOperationCode("getGroupUser");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");
        mqttRequests.publish(mqttClient, "user/getGroupUser", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
    }

    //receive the id of a right and send the corresponding name for display it in the page
    const findNameFromId = (idRights) => {

        //find the right name by searching in the table with all the rights (allRights)
        let response =  "";
        allRights.forEach((element) => {
            if (element.id === idRights){
                response = element.name;
            }
        })
        return response;
    }

    return(
        <div>
            <table className="baseTableNoBorder">
                <tbody>
                {rightsInGroup.map(function (right, id) {
                    return (
                        <tr className="baseTr" key={id}>
                            <td className="alarmBoldSpan"></td>
                            <td className="baseTdFlex">
                                <span>{findNameFromId(right)}</span>
                                <img src={deleteButton} alt="X" className="deviceDeleteButton" onClick={() => handleDelete(right)}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            {!formVisible?
                <>
                    <button className="alarmButtonManage" onClick={handleDisplayForm}>Add rights to group</button>
                    <button className="alarmButton" onClick={() => setEditGroup('')}>Cancel</button>
                </>
                :
                <div className="userFormControl">
                    <FormControl sx={{width: 1}}>
                        <InputLabel id="selectMultipleUsers" className="deviceInputLabel">Select rights</InputLabel>
                        <Select
                            id="SelectUser"
                            multiple
                            value={rightsToAdd}
                            onChange={handleChange}
                            input={<OutlinedInput label="Select users" sx={{color:'#fff'}}/>}
                            renderValue={(selected) => selected.join(', ')}
                            className="deviceSelect"
                            inputProps={{MenuProps:{PaperProps:{sx:{backgroundColor:'#aaa'}}}}}
                        >
                            {rightsNotInGroup.sort((a,b) => a > b ? 1 : -1,).map((name) => (
                                <MenuItem key={name} value={name} className="deviceMenuItem">
                                    <Checkbox checked={rightsToAdd.indexOf(name) > -1} sx={{[`&.${checkboxClasses.checked}`]: {color:'#000'}}}/>
                                    <ListItemText primary={name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br/>
                    <button onClick={handleAddRights} className="UserButton">Validate</button>
                    <button onClick={() => setFormVisible(false)} className="UserButton">Cancel</button>
                </div>
            }
            <Snackbar open={warnEmptyOpen} autoHideDuration={4000} onClose={() => setWarnEmptyOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">All rights are already given to this group</Alert>
            </Snackbar>
        </div>
    )
}

export default GetGroupRight;
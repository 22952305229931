import React, {useContext, useEffect, useState} from 'react';
import {
    CartesianGrid,
    Legend,
    ReferenceArea,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import * as Yup from "yup";
import mqttRequests from "../../Utils/mqttRequests";
import {Form, Formik} from "formik";
import FormikControl from "../FormTemplate/FormikControl";
import moment from "moment";
import {
    Alert,
    Slider,
    Snackbar
} from "@mui/material";
import {MqttClientContext} from "../../context/MqttClientContext";
import "./Devices.scss";
import {useNavigate} from "react-router-dom";
import {UserInfoContext} from "../../context/UserInfoContext";
import LineYes from "../../assets/LinesYes.png";
import LineNo from "../../assets/LinesNo.png";
import DotsYes from "../../assets/DotsYes.png";
import DotsNo from "../../assets/DotsNo.png";

//the page to display device data
function GroupDataBattery(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('getGroupDevice');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/getGroupDevice")
        }, false);
    }, [navigate]);

    //variables for display
    const [chartHidden, setChartHidden] = useState(true);
    const [errMessage, setErrMessage] = useState(false);
    const [errMessageDevice, setErrMessageDevice] = useState(false);

    //the number of points display in the chart (1/1 1/2 1/3...) 1/3 -> 3
    const [numberReduced, setNumberReduce] = useState(1);

    //variables for time
    const [unitTime, setUnitTime] = useState("sec");
    //for the seconds of the last point
    const [maxTime, setMaxTime] = useState(0);

    //variables for battery
    const [battery, setBattery] = useState([]);
    const [batteryDisplayed, setBatteryDisplayed] = useState([]);
    const [battery2, setBattery2] = useState([]);
    const [battery2Displayed, setBattery2Displayed] = useState([]);
    const [battery3, setBattery3] = useState([]);
    const [battery3Displayed, setBattery3Displayed] = useState([]);
    const [battery4, setBattery4] = useState([]);
    const [battery4Displayed, setBattery4Displayed] = useState([]);
    //labels for battery
    const [labelBattery, setLabelBattery] = useState("");
    const [labelBattery2, setLabelBattery2] = useState("");
    const [labelBattery3, setLabelBattery3] = useState("");
    const [labelBattery4, setLabelBattery4] = useState("");

    //variables to choose to display or not Teltonika device values (B -> battery)
    const [B1Display, setB1Display] = useState(true);
    const [B2Display, setB2Display] = useState(true);
    const [B3Display, setB3Display] = useState(true);
    const [B4Display, setB4Display] = useState(true);

    //variables for checkboxes
    //for line and dots display
    const [lineRender, setLineRender] = useState(true);
    const [shapeRender, setShapeRender] = useState(true);

    //variables to select min and max for y-axis
    const [boundsBatteryYAxis, setBoundsBatteryYAxis] = useState([dataMin => Math.ceil(dataMin - 30), dataMax => Math.floor(dataMax + 30)]);

    //variables to display min and max for y-axis
    const [displayBoundsBattery, setDisplayBoundsBattery] = useState([0, 0]);

    //variables to save min and max display for y-axis
    const [saveBoundsBattery, setSaveBoundsBattery] = useState([0, 0]);

    //the devices
    const [devices, setDevices] = useState([]);

    const [historyTotalTime, setHistoryTotalTime] = useState(0);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);
    const {idAccount} = useContext(UserInfoContext);

    //variables to zoom
    const [stateZoom, setStateZoom] = useState({
        left: 'dataMin',
        right: dataMax => Math.ceil(dataMax),
        refAreaLeft: '',
        refAreaRight: '',
        animation: true,
    });

    //get the device
    let group = JSON.parse(localStorage.getItem("groupData"));
    let groupNameData = localStorage.getItem("groupNameData");

    //the initial values for formik
    const initialValues = {
        beginning: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(new Date()).format('YYYY-MM-DD'),
    };

    //set the display battery value of the fields for the bounds selecting
    useEffect(() => {
        let min = 100000;
        let max = -100000;
        if (batteryDisplayed.length !== 0) {
            batteryDisplayed.forEach((element, index) => {
                if (element.battery < min) {
                    min = element.battery;
                }
                if (element.battery > max) {
                    max = element.battery
                }
            })
        }
        if (battery2Displayed.length !== 0) {
            battery2Displayed.forEach((element, index) => {
                if (element.battery < min) {
                    min = element.battery;
                }
                if (element.battery > max) {
                    max = element.battery
                }
            })
        }
        if (battery3Displayed.length !== 0) {
            battery3Displayed.forEach((element, index) => {
                if (element.battery < min) {
                    min = element.battery;
                }
                if (element.battery > max) {
                    max = element.battery
                }
            })
        }
        if (battery4Displayed.length !== 0) {
            battery4Displayed.forEach((element, index) => {
                if (element.battery < min) {
                    min = element.battery;
                }
                if (element.battery > max) {
                    max = element.battery
                }
            })
        }
        setDisplayBoundsBattery([min - 100, max + 100]);
        setSaveBoundsBattery([min - 100, max + 100]);
    }, [batteryDisplayed, battery2Displayed, battery3Displayed, battery4Displayed]);

    //set the total time, the max time and the unit time
    useEffect(() => {
        //find the latest data
        let totalTime = Math.round(stateZoom.right - stateZoom.left);

        if (!isNaN(totalTime)) {
            //choose the unit for the time depending on the duration of the data (data during how much time)
            switch (true) {
                //less than 2 min - unit in seconds
                case (totalTime < 120):
                    setUnitTime("min");
                    break;
                //less than 5h - unit in minutes
                case (totalTime < 18000):
                    setUnitTime("min");
                    break;
                //less than 5 days - unit in hours
                case (totalTime < 432000):
                    setUnitTime("h");
                    break;
                //less than 180 days - unit in days
                case (totalTime < 15552000):
                    setUnitTime("days");
                    break;
                //less than 3 years - unit in month
                case (totalTime < 94608000):
                    setUnitTime("month");
                    break;
                //more than 3 years - unit in year
                case (totalTime > 9460800):
                    setUnitTime("year");
                    break;
                default:
                    break;
            }
            //update the maxTime;
            setMaxTime(totalTime);
        }

    }, [stateZoom.left, stateZoom.right]);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //get the devices
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getAllDevices");
            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //read incoming message and set the variables
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //the list of all devices in the db
                if(jsonParsed.devices){
                    setDevices(jsonParsed.devices);
                    //the list of all different datatype in the db with id and name
                }else if (jsonParsed.data) {
                    //set the real timestamp of the first point
                    manageData(jsonParsed.data, jsonParsed.firstPoint, jsonParsed.identifier);
                    //display the chart
                    setChartHidden(false);
                }

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, idAccount, mqttClient]);

    //return labels for the xAxis of all charts displaying the time in duration and in days/month/year
    function CustomizedTick(props) {
        const {x, y, payload} = props;
        //find the duration between the first point and now

        //set the actual time in date format
        let displayDate = new Date(payload.value);
        //display the infos differently according to the duration of the data asked
        switch (true) {
            //less than 5 days : days + hours
            case (maxTime < 432000):
                displayDate = moment(displayDate).format('MM/DD-HH:mm');
                break;
            //less than 4 month : days
            case (maxTime < 10512008):
                displayDate = moment(displayDate).format('MM/DD');
                break;
            //less than 2.5 years : month + year
            case (maxTime < 78840000):
                displayDate = moment(displayDate).format('MM-YYYY');
                break;
            //2.5 years or more : year
            case (maxTime >= 78840000):
                displayDate = moment(displayDate).format('YYYY');
                break;
            default:
                break;
        }

        //return a value in duration and a value in date format
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0" dy="20">
                        {displayDate}
                    </tspan>
                </text>
            </g>
        );
    }

    //returns the customized data when a user pass the mouse in a point in the chart
    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return (
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date(payload[0].payload.timestamp)).format('YYYY-MM-DD HH:mm')}</p>
                    <p>External battery: {`${Object.values(payload[0].payload)[1]}mV`}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    //to ask mqtt to give points between time in the form
    const submitMethod = (value) => {
        //check if the beginning date is before the end date
        if (new Date(value.end) - new Date(value.beginning) < 0) {
            setErrMessage(true)
        } else {
            //ask for data
            //generate operation code
            const operation = mqttRequests.generateOperationCode("dataAsked");

            //get the start of the day in our timezone
            let beginDate = new Date(value.beginning).getTime() / 1000 + new Date().getTimezoneOffset() * 60;
            //get the end of the day in our timezone (get the start of the day + 86399 which goes to 23h59 59sec the same day)
            let finishDate = (new Date(value.end).getTime() / 1000) + new Date().getTimezoneOffset() * 60 + 86399;

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/dataAsked");

            let count = 0;
            group.forEach((element) => {
                count  = count + 1;
                if(count <= 4){
                    let idModel = "";
                    let datatypes = [];
                    devices.forEach((device) => {
                        if (device.identifier === element){
                            datatypes = device.dataType;
                            idModel = device.idModel;
                        }
                    })

                    //create json to publish
                    const data = '{"operation":"' + operation + '", "source":"' + source + '", "identifier":"' + element + '",' +
                        ' "from":"' + beginDate + '", "to":"' + finishDate + '", "idModel":"' + idModel + '", "dataType":[' + datatypes + '], "sortType":0}';

                    //publish a demand to get the data from a device
                    mqttRequests.publish(mqttClient, "data/dataAsked", data);
                }
            });

        }
    };

    //manage data
    const manageData = (data, firstPoint, identifier) => {
        //reset the slider change
        setNumberReduce(1);

        if(data.time.length !== 0){
            //find the latest data
            let totalTime = data.time[data.time.length - 1];
            setHistoryTotalTime(totalTime);

            let divisionNumber = 1;
            //choose the unit for the time depending on the duration of the data (data during how much time)
            switch (true) {
                //less than 2 min - unit in seconds
                case (totalTime < 120):
                    break;
                //less than 5h - unit in minutes
                case (totalTime < 18000):
                    setUnitTime("min");
                    divisionNumber = 60;
                    break;
                //less than 5 days - unit in hours
                case (totalTime < 432000):
                    setUnitTime("h");
                    divisionNumber = 3600;
                    break;
                //less than 180 days - unit in days
                case (totalTime < 15552000):
                    setUnitTime("days");
                    divisionNumber = 86400;
                    break;
                //less than 3 years - unit in month
                case (totalTime < 94608000):
                    setUnitTime("month");
                    divisionNumber = 2628002;
                    break;
                //more than 3 years - unit in year
                case (totalTime > 9460800):
                    setUnitTime("year");
                    divisionNumber = 31536000;
                    break;
                default:
                    break;
            }

            //set the max timestamp
            setMaxTime(data.time[data.time.length - 1]);

            //call method to manage battery
            setBatteryData(data, divisionNumber, firstPoint, identifier);
        }
    }

    //manage battery
    const setBatteryData = (data, divisionNumber, firstPoint, identifier) => {
        let deviceName = identifier;

        //set variable for the external battery
        let tempBattery = data.BatteryExternalmV.map((id, index_value) => {
            return {
                timestamp: (data["time"][index_value] + firstPoint) * 1000,
                battery: data.BatteryExternalmV[index_value],
            }
        });

        let numberReduce = 1;
        if (tempBattery.length < 100) {
            if(group[0] === deviceName){
                setBatteryDisplayed(tempBattery);
            } else if(group[1] === deviceName) {
                setBattery2Displayed(tempBattery);
            } else if(group[2] === deviceName) {
                setBattery3Displayed(tempBattery);
            } else if(group[3] === deviceName) {
                setBattery4Displayed(tempBattery);
            }
        } else {
            if (tempBattery.length / 100 <= 100) {
                numberReduce = Math.ceil(tempBattery.length / 100);
            } else {
                numberReduce = 100;
            }
            setNumberReduce(numberReduce);
        }

        //set temperature and humidity = all points
        if(group[0] === deviceName){
            setBattery(tempBattery);
            displayLessPointsBattery(tempBattery, numberReduce, setBatteryDisplayed, "percent");
            setLabelBattery(deviceName);
        } else if(group[1] === deviceName) {
            setBattery2(tempBattery);
            displayLessPointsBattery(tempBattery, numberReduce, setBattery2Displayed, "percent");
            setLabelBattery2(deviceName);
        } else if(group[2] === deviceName) {
            setBattery3(tempBattery);
            displayLessPointsBattery(tempBattery, numberReduce, setBattery3Displayed, "percent");
            setLabelBattery3(deviceName);
        } else if(group[3] === deviceName) {
            setBattery4(tempBattery);
            displayLessPointsBattery(tempBattery, numberReduce, setBattery4Displayed, "percent");
            setLabelBattery4(deviceName);
        }
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for battery
    const displayLessPointsBattery = (batteryTable, number, actionBattery) => {
        if(batteryTable.length !== 0){
            //reduce the number of points for battery
            let modulo = -1;
            let tempBattery = batteryTable.map((temp) => {
                modulo++;
                if (modulo % number === 0) {
                    return {
                        timestamp: temp.timestamp,
                        battery: temp.battery,
                    }
                }
                return undefined;
            })
            //actionTemperature = setTemperatureDisplayed, we remove undefined values
            actionBattery(tempBattery.filter(element => element !== undefined));
        }
    }

    //actions when the user change the slider (modify the display of the slider)
    const handleSliderChange = (event, newValue) => {
        setNumberReduce(newValue);
    }

    //action when the user release the slider (modify the number of points in variables)
    const handleSliderChangeCommited = (event, newValue) => {
        if(battery.length !== 0){
            displayLessPointsBattery(battery, newValue, setBatteryDisplayed);
        }
        if(battery2.length !== 0){
            displayLessPointsBattery(battery2, newValue, setBattery2Displayed);
        }
        if(battery3.length !== 0){
            displayLessPointsBattery(battery3, newValue, setBattery3Displayed);
        }
        if(battery4.length !== 0){
            displayLessPointsBattery(battery4, newValue, setBattery4Displayed);
        }
    }

    //method to zoom, activate when the user release the click
    const zoom = () => {
        //recover refAreaLeft and refAreaRight from the state
        let {refAreaLeft, refAreaRight} = stateZoom;

        //if no ref right or the 2 are the same (user made a simple click on the chart), don't zoom and reset the refs
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: '',
                refAreaRight: '',
            }))
            return;
        }

        try {
            // set the right and the left area in the correct order (if the user select from right to left)
            if (refAreaLeft > refAreaRight) {
                [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
            }

            //select the first element and the last element which must be displayed
            let startElement = -1;
            let endElement = -1;
            if(batteryDisplayed.length !== 0){
                batteryDisplayed.forEach((element, index) => {
                    if (startElement === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement = index;
                        }
                    }
                    if (endElement === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement = index;
                        }
                    }
                })
            }

            let startElement2 = -1;
            let endElement2 = -1;
            if(battery2Displayed.length !== 0){
                battery2Displayed.forEach((element, index) => {
                    if (startElement2 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement2 = index;
                        }
                    }
                    if (endElement2 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement2 = index;
                        }
                    }
                })
            }

            let startElement3 = -1;
            let endElement3 = -1;
            if(battery3Displayed.length !== 0){
                battery3Displayed.forEach((element, index) => {
                    if (startElement3 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement3 = index;
                        }
                    }
                    if (endElement3 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement3 = index;
                        }
                    }
                })
            }

            let startElement4 = -1;
            let endElement4 = -1;
            if(battery4Displayed.length !== 0){
                battery4Displayed.forEach((element, index) => {
                    if (startElement4 === -1) {
                        if (element.timestamp >= refAreaLeft) {
                            startElement4 = index;
                        }
                    }
                    if (endElement4 === -1) {
                        if (element.timestamp >= refAreaRight) {
                            endElement4 = index;
                        }
                    }
                })
            }

            //restrain the number of data in the variables displayed temperature and humidity
            setBatteryDisplayed(batteryDisplayed.slice(startElement, endElement));
            setBattery2Displayed(battery2Displayed.slice(startElement2, endElement2));
            setBattery3Displayed(battery3Displayed.slice(startElement3, endElement3));
            setBattery4Displayed(battery4Displayed.slice(startElement4, endElement4));

            //set the new state of zoom
            setStateZoom({
                refAreaLeft: '',
                refAreaRight: '',
                left: refAreaLeft,
                right: refAreaRight,
                animation: true,
            });
            //in case of error, display it in the console
        } catch (err) {
            console.error(err);
        }
    }

    //method to zoom out, reset the values at as they were before the zoom
    const zoomOut = () => {
        //reset the values for battery
        displayLessPointsBattery(battery, numberReduced, setBatteryDisplayed);
        displayLessPointsBattery(battery2, numberReduced, setBattery2Displayed);
        displayLessPointsBattery(battery3, numberReduced, setBattery3Displayed);
        displayLessPointsBattery(battery4, numberReduced, setBattery4Displayed);

        let totalTime = historyTotalTime;

        //reset the division number
        //choose the unit for the time depending on the duration of the data (data during how much time)
        switch (true) {
            //less than 2 min - unit in seconds
            case (totalTime < 120):
                setUnitTime("min");
                break;
            //less than 5h - unit in minutes
            case (totalTime < 18000):
                setUnitTime("min");
                break;
            //less than 5 days - unit in hours
            case (totalTime < 432000):
                setUnitTime("h");
                break;
            //less than 180 days - unit in days
            case (totalTime < 15552000):
                setUnitTime("days");
                break;
            //less than 3 years - unit in month
            case (totalTime < 94608000):
                setUnitTime("month");
                break;
            //more than 3 years - unit in year
            case (totalTime > 9460800):
                setUnitTime("year");
                break;
            default:
                break;
        }
        //update the maxTime;
        setMaxTime(totalTime);

        //reset the state
        setStateZoom({
            left: 'dataMin',
            right: dataMax => Math.ceil(dataMax),
            refAreaLeft: '',
            refAreaRight: '',
            animation: true,
        });
    }

    //activate when a user click down, set the state refAreaLeft
    const handleMouseDown = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: e.xValue,
            }))
        }
    }

    //activate when a user move the mouse in the plot, set the state refAreaRight
    const handleMouseMove = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaRight: e.xValue,
            }))
        }
    }

    //function to reset axis for battery mV
    const resetYAxisBattery = () => {
        setBoundsBatteryYAxis([dataMin => Math.ceil(dataMin - 100), dataMax => Math.floor(dataMax + 100)]);
        setDisplayBoundsBattery(saveBoundsBattery)
    }

    //changes when user modify the form
    const handleFormBoundsBatteryFirst = e => {
        setBoundsBatteryYAxis([parseInt(e.target.value), boundsBatteryYAxis[1]]);
        setDisplayBoundsBattery([parseInt(e.target.value), displayBoundsBattery[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsBatteryLast = e => {
        setBoundsBatteryYAxis([boundsBatteryYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsBattery([displayBoundsBattery[0], parseInt(e.target.value)]);
    }

    const nameDeviceFromId = (id) => {
        let response = id;
        devices.forEach(devices => {
            if(devices.identifier === id){
                response = devices.customName + '(' + id + ')';
            }
        })
        return response
    }

    const nameDeviceFromIdNoId = (id) => {
        let response = id;
        devices.forEach(devices => {
            if(devices.identifier === id){
                response = devices.customName;
            }
        })
        return response
    }

    //display the form, the button to download, the charts and the map
    return (
        <div className="baseMainDiv">
            <h2 className="baseTitle">Data from group: {groupNameData}</h2>
            <div className="devicesFlexboxDiv">
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object({})}
                    onSubmit={submitMethod}
                >
                    {() => {
                        return (
                            <Form>
                                <div className="devicesDivTxt">
                                    <FormikControl control="input" type="date" label="From: " name="beginning"/>
                                    <FormikControl control="input" type="date" label="To: " name="end"/>
                                </div>
                                <button type="submit" className="devicesButton">Validate</button>
                            </Form>
                        )
                    }}
                </Formik>
                <div className="devicesFlexboxDivLeftDisplay"></div>
            </div>
            <div hidden={chartHidden}>
                <div className="devicesFlexboxDivCenter">
                    {lineRender ?
                        <img src="" onClick={() => setLineRender(!lineRender)} alt="Lines" style={{color:"#06c"}}/>
                        :
                        <img src="" onClick={() => setLineRender(!lineRender)} alt="Lines"/>
                    }
                    {shapeRender ?
                        <img src="" onClick={() => setShapeRender(!shapeRender)} alt="Dots" style={{color:"#06c"}}/>
                        :
                        <img src="" onClick={() => setShapeRender(!shapeRender)} alt="Dots"/>
                    }
                </div>
                <div className="devicesFlexboxDivSlider">
                    <p>How many points do you want to render: 1/</p>
                    <Slider style={{width: 300, marginTop: 14, marginLeft: 15}} value={numberReduced} step={1}
                            min={1} max={100} valueLabelDisplay={"on"} track={false} onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommited}/>
                </div>
                <>
                    <div className="devicesFlexboxDivSpaceEvenly">
                        <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                        {battery.length !== 0 ?
                            <div className="devicesFlexboxDivLeft">
                                <p className="devicesFlexTextBoundsYaxis">Battery bounds:</p>
                                <input type='number' value={displayBoundsBattery[0]}
                                       onChange={handleFormBoundsBatteryFirst} className="devicesInput"/>
                                <input type='number' value={displayBoundsBattery[1]}
                                       onChange={handleFormBoundsBatteryLast} className="devicesInput"/>
                                <button className="devicesButton" onClick={resetYAxisBattery}>Reset
                                </button>
                            </div>
                            :
                            null
                        }
                    </div>
                    <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                        <ScatterChart
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={zoom}
                            margin={{
                                top: 20,
                                right: 40,
                                bottom: 10,
                                left: 45
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                                   name="time" height={50} label={{
                                value: "Time (" + unitTime + ")",
                                position: "insideBottomLeft",
                                dx: 0,
                                dy: 5
                            }} domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}/>
                            {battery.length !== 0 || battery2.length !== 0 || battery3.length !== 0 || battery4.length !== 0?
                                <YAxis className="deviceRechartsUserSelection" dataKey="battery" type="number"
                                       name="battery" domain={boundsBatteryYAxis} yAxisId="left"
                                       orientation="left" stroke="#909" unit="mV"
                                       label={{
                                           value: "External Battery (mV)",
                                           angle: -90,
                                           dy: 60,
                                           dx: -35,
                                           position: "insideLeft",
                                           fill: "#909"
                                       }} allowDataOverflow={true}/>
                                :
                                null
                            }
                            <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                            <Legend/>
                            {B1Display ?
                                <>
                                    {battery.length !== 0 ?
                                        <Scatter name={nameDeviceFromId(labelBattery)} data={batteryDisplayed}
                                                 line={{stroke: "#909", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "circle" : null}
                                                 yAxisId="left" fill="#909"/>

                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                            {B2Display ?
                                <>
                                    {battery2.length !== 0 ?
                                        <Scatter name={nameDeviceFromId(labelBattery2)} data={battery2Displayed}
                                                 line={{stroke: "#909", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "diamond" : null} legendType="diamond"
                                                 yAxisId="left" fill="#909"/>
                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                            {B3Display ?
                                <>
                                    {battery3.length !== 0 ?
                                        <Scatter name={nameDeviceFromId(labelBattery3)} data={battery3Displayed}
                                                 line={{stroke: "#909", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "triangle" : null} legendType="triangle"
                                                 yAxisId="left" fill="#909"/>
                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                            {B4Display ?
                                <>
                                    {battery4.length !== 0 ?
                                        <Scatter name={nameDeviceFromId(labelBattery4)} data={battery4Displayed}
                                                 line={{stroke: "#909", strokeWidth: lineRender ? 2 : 0}}
                                                 shape={shapeRender ? "square" : null} legendType="square"
                                                 yAxisId="left" fill="#909"/>
                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                            {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                                    <ReferenceArea yAxisId="left" x1={stateZoom.refAreaLeft}
                                                   x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                                :
                                null
                            }
                        </ScatterChart>
                    </ResponsiveContainer>
                    <div className="devicesFlexboxDivCenter">
                        <p className="devicePaddingTop">Devices displayed: </p>
                        {battery.length !== 0 ?
                            <div className="devicesFlexboxDiv">
                                <input className="deviceCheckbox" type="checkbox" id="deviceB1" name="device1"
                                       onClick={() => setB1Display(!B1Display)} checked={B1Display}
                                       readOnly="yes"/>
                                <p className="deviceDisplayedText">{nameDeviceFromIdNoId(labelBattery)}</p>
                            </div>
                            :
                            null
                        }
                        {battery2.length !== 0 ?
                            <div className="devicesFlexboxDiv">
                                <input className="deviceCheckbox" type="checkbox" id="deviceB2" name="device2"
                                       onClick={() => setB2Display(!B2Display)} checked={B2Display}
                                       readOnly="yes"/>
                                <p className="deviceDisplayedText">{nameDeviceFromIdNoId(labelBattery2)}</p>
                            </div>
                            :
                            null
                        }
                        {battery3.length !== 0 ?
                            <div className="devicesFlexboxDiv">
                                <input className="deviceCheckbox" type="checkbox" id="deviceB3" name="device3"
                                       onClick={() => setB3Display(!B3Display)} checked={B3Display}
                                       readOnly="yes"/>
                                <p className="deviceDisplayedText">{nameDeviceFromIdNoId(labelBattery3)}</p>
                            </div>
                            :
                            null
                        }
                        {battery4.length !== 0 ?
                            <div className="devicesFlexboxDiv">
                                <input className="deviceCheckbox" type="checkbox" id="deviceB4" name="device4"
                                       onClick={() => setB4Display(!B4Display)} checked={B4Display}
                                       readOnly="yes"/>
                                <p className="deviceDisplayedText">{nameDeviceFromIdNoId(labelBattery4)}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </>

            </div>
            <div className="DeviceBackButton">
                <button onClick={() => navigate("/getGroupDevice")} className="devicesButton">Back</button>
            </div>
            <Snackbar open={errMessage} autoHideDuration={4000} onClose={() => setErrMessage(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - end date before begin date</Alert>
            </Snackbar>
            <Snackbar open={errMessageDevice} autoHideDuration={4000} onClose={() => setErrMessageDevice(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Select at least 1 device</Alert>
            </Snackbar>
        </div>
    )
}

export default GroupDataBattery;
import React, {useContext, useEffect, useState} from 'react';
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import "./Devices.scss"
import {Alert, Dialog, DialogActions, DialogTitle, Snackbar} from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import EditGroupDevice from "./EditGroupDevice";
import AddGroupDevice from "./AddGroupDevice";
import GetGroupDeviceDevice from "./GetGroupDeviceDevice";

//the page witch display all the device group from an account
function GetGroupDevice(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('getGroupDevice');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);
    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //set variable to get the data
    const [groupDevice, setGroupDevice] = useState([]);
    //all devices in the account
    const [allDevices, setAllDevices]= useState([]);

    //variable to rerender the data
    const [reshow, setReshow] = useState(false);
    //set variable for the dialog (message "are you sure to hide")
    const[dialogOpen, setDialogOpen] = useState(false);
    //set variable to store the group name
    const [name, setName] = useState("");
    //set variable for the action Hide/show
    const [action, setAction] = useState("");
    //for devices in group
    const [devicesInGroup, setDevicesInGroup] = useState([]);

    //variable to show the success popup when the user edited a group
    const [groupDeviceEdited, setGroupDeviceEdited] = useState(false);
    const [errOneOpen, setErrOneOpen] = useState(false);
    const [errTwoOpen, setErrTwoOpen] = useState(false);
    const [errThreeOpen, setErrThreeOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);

    const [editGroupDevice,setEditGroupDevice] = useState('');
    const [addGroupDevice, setAddGroupDevice] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getGroupDevice");

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDevice");

            //publish a demand to get the group devices
            mqttRequests.publish(mqttClient, "device/getGroupDevice", data);

            //get all the devices
            operation = mqttRequests.generateOperationCode("getAllDevices");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //read incoming message and send response to a function to refactor them
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //if it's a response to getGroupDevice
                if (jsonParsed.deviceGroupName) {
                    if (jsonParsed.result === 1) {
                        refactorData(jsonParsed);
                    }
                    //if it's a response to hide/show
                }else if(jsonParsed.operation.includes("getGroupDeviceDevice")){
                    if(jsonParsed.result === 1){
                        setDevicesInGroup(jsonParsed.idDevice);
                    }
                }else if(jsonParsed.devices){
                    setAllDevices(jsonParsed.devices);
                }else if(jsonParsed.operation.includes("hide") || jsonParsed.operation.includes("show")){
                    if(jsonParsed.result && jsonParsed.result!==1){
                        console.error(jsonParsed.result);
                    }
                }else if(jsonParsed.operation.includes("editGroupDevice")){
                    switch (jsonParsed.result){
                        case 1:
                            setGroupDeviceEdited(true);
                            //go to getGroupDevice page
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        case -3:
                            //display error popup
                            setErrThreeOpen(true);
                            break;
                        default :
                            break;
                    }
                } else if(jsonParsed.operation.includes("addGroupDevice")){
                    if(jsonParsed.idGroup > 0){
                        //display success popup
                        setSuccessOpen(true);
                    } else {
                        if (jsonParsed.idGroup === "-1") {
                            //display error -1 popup
                            setErrOneOpen(true);
                        } else if (jsonParsed.idGroup === "-2"){
                            //display error -2 popup
                            setErrTwoOpen(true);
                        }
                    }
                }

            })
        }
    },[source, idAccount, mqttClient, reshow]);

    useEffect(() => {
        if(groupDevice.length !== 0 && allDevices.length !==0){
            let alreadyChanged = false
            groupDevice.forEach((element) => {
                if(element.devices !== undefined){
                    if(element.devices[0].includes('(')){
                        alreadyChanged = true;
                    }
                }
            })
            if(!alreadyChanged){
                setGroupDevice(groupDevice.map((element) =>{
                    return {
                        groupName: element.groupName,
                        description: element.description,
                        hide: element.hide,
                        devices: element.devices === undefined ? undefined : element.devices.map((device) => {
                            return getDataToDisplay(device);
                        }),
                    }
                }));
            }
        }
    },[allDevices, groupDevice]);

    //transform 3 separates arrays in 1 object with all the values to make it easier to render
    const refactorData = (data) => {
        setGroupDevice(data.deviceGroupName.map((id, index_value) =>{
            return {
                groupName: data.deviceGroupName[index_value],
                description: data.deviceGroupDescr[index_value],
                hide: data.deviceGroupHide[index_value],
                devices: data.devicesInGroup[data.idGroupDevice[index_value]],
            }
        }));
    };

    //function to go to the page to manage the users in the group
    const handleManageDevice = (groupName) => {
        localStorage.setItem("groupNameManage", groupName);
        setEditGroupDevice('manage' + groupName)
    }

    //function to go to the edit group page
    const handleEditGroupDevice = (group) => {
        localStorage.setItem("groupDeviceEdit", JSON.stringify(group));
        setEditGroupDevice(group.groupName);
    }

    //display the dialog "are you sure you want to do that ?"
    const handleClickHide = (action, name) => {
        //set the action and the name
        setAction(action);
        setName(name);

        //open the dialog
        setDialogOpen(true);
    }

    // go to view group and store devices inside
    const handleViewData = (group) => {
        localStorage.setItem("groupNameData", group.groupName);
        let arrayIdentifier = group.devices.map((element) => {
            return element.substring(element.indexOf("(")+1, element.lastIndexOf(")"));
        });
        if(arrayIdentifier.filter((element)=>{return element.length < 13}).length !== 0){
            localStorage.setItem("groupData", JSON.stringify(arrayIdentifier.filter((element)=>{return element.length < 13})));
            navigate("/viewGroupData");
        }else {
            localStorage.setItem("groupData", JSON.stringify(arrayIdentifier.filter((element)=>{return element.length >= 13})));
            navigate("/viewGroupDataB");
        }


    }

    //manage the hide or show group user
    const manageHideShow = () => {
        //close the confirmation dialog
        setDialogOpen(false);

        //correct the action to use the correct name to interact with mqtt (subscribe and post in the right channel)
        let realAction = action + "GroupDevice";

        //generate operation code
        const operation = mqttRequests.generateOperationCode(realAction);

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupDevice":"' + name + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/" + realAction);

        //publish a demand to hide or show the group
        mqttRequests.publish(mqttClient, "device/" + realAction, data);

        //recharge data
        setReshow(!reshow);
    }

    //select the data to display from the idDevice
    const getDataToDisplay = (identifier) => {
        //find the right data to display by searching in the table with all the devices (allDevices)
        let response =  "";
        allDevices.forEach((element) => {
            if (element.identifier === identifier){
                response = element.customName + ' (' + element.identifier + ')'
            }
        })
        return response;
    }

    return(
        <div className="baseMainDiv">
            <h2 className="baseTitle">List of all device group</h2>
                <div className="baseDivAccordion">
                <Accordion alwaysOpen>
                    {groupDevice.sort((a,b) => a.groupName > b.groupName ? 1 : -1,).map((group, id) => {
                        return(
                            <div key={id}>
                                <Accordion.Item eventKey={id}>
                                    <Accordion.Header>{group.groupName}</Accordion.Header>
                                    <Accordion.Body className="baseAccordionBody">
                                        <table className="baseTableNoBorder">
                                            <tbody>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Group Name:</td>
                                                <td className="baseTd">{group.groupName}</td>
                                            </tr>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Description:</td>
                                                <td className="baseTd">{group.description !== "" ? group.description : "-"}</td>
                                            </tr>
                                            <tr className="baseTr">
                                                <td className="deviceBoldSpan">Hide</td>
                                                <td className="baseTd">{group.hide === 0 ? "No" : "Yes"}</td>
                                            </tr>

                                            {group.devices !== undefined ?
                                                group.devices.sort((a,b) => a > b ? 1 : -1,).map((element, subId)=>{
                                                    return(
                                                        <tr className="baseTr" key={subId}>
                                                            <td className="deviceBoldSpan">{subId === 0 ? "Devices:" : ""}</td>
                                                            <td className="baseTd" key={subId}>- {element}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Devices:</td>
                                                    <td className="baseTd">-</td>
                                                </tr>
                                            }

                                            <tr>
                                                <td className="deviceBoldSpan"></td>
                                                <td>
                                                    <button className="devicesButton" onClick={() => handleManageDevice(group.groupName)}>Manage device</button>
                                                    <button className="devicesButton" onClick={() => handleEditGroupDevice(group)}>Edit group</button>
                                                    {group.devices !== undefined ?
                                                        <button className="devicesButton" onClick={() => handleViewData(group)}>View data</button>
                                                        :
                                                        null
                                                    }
                                                    {group.hide === 0?
                                                        <button className="devicesButton" onClick={() => handleClickHide("hide", group.groupName)}>Hide</button>
                                                        :
                                                        <button className="devicesButton" onClick={() => handleClickHide("show", group.groupName)}>Show</button>
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {editGroupDevice === group.groupName ?
                                            <div className="deviceEdit">
                                                <EditGroupDevice setEditGroupDevice={setEditGroupDevice}/>
                                            </div>
                                            :
                                            null
                                        }
                                        {editGroupDevice === 'manage' + group.groupName ?
                                            <div className="deviceEdit">
                                                <GetGroupDeviceDevice setEditGroupDevice={setEditGroupDevice} allDevices={allDevices} devicesInGroup={devicesInGroup}/>
                                            </div>
                                            :
                                            null
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                        )
                    })}

                    </Accordion>
                    <button onClick={() => setAddGroupDevice(true)} className="devicesButton">Add Group</button>

                    {addGroupDevice?
                        <AddGroupDevice setAddGroupDevice={setAddGroupDevice}/>
                        :
                        null
                    }
                </div>

            <Snackbar open={groupDeviceEdited} autoHideDuration={4000} onClose={() => setGroupDeviceEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Group successfully edited</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Group device not found</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={errThreeOpen} autoHideDuration={4000} onClose={() => setErrThreeOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Group name already exists</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Group successfully added</Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure to " + action + " this device group ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="devicesButton" onClick={() => setDialogOpen(false)}>No</button>
                    <button className="devicesButton" onClick={manageHideShow}>Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GetGroupDevice;
import React, {useContext, useEffect, useState} from 'react';
import './Mission.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import {Alert, Snackbar} from "@mui/material";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import EditMission from "./EditMission";
import AddMission from "./AddMission";

//the page witch display all the mission available for a user
function GetMissions(props) {

    //remove the color on the menu
    let setMenuClicked = props.setMenuClicked;
    setMenuClicked('missions');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idUser, idRight} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //set variable to get the data
    const [missions, setMissions] = useState([]);

    //set variable to display an error popup
    const [unknownError, setUnknownError] = useState(false);
    //set variable to display the popup in case of mission edited
    const [missionEdited, setMissionEdited] = useState(false);
    const[errOneOpen, setErrOneOpen] = useState(false);
    const[errTwoOpen, setErrTwoOpen] = useState(false);
    const[successOpen, setSuccessOpen] = useState(false);

    //if user has or not the right to add missions
    const [addMissionRights, setAddMissionRights] = useState(false);

    const [editMission, setEditMission] = useState('');
    const [missionAdd, setMissionAdd] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getMissions");

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getMissions");

            //publish a demand to get all the missions
            mqttRequests.publish(mqttClient, "mission/getMissions", data);

            //read incoming message and set the mission variable
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                if(jsonParsed.mission){
                    if(jsonParsed.result===1){
                        setMissions(jsonParsed.mission);
                    }else{
                        setUnknownError(true);
                    }
                }else if(jsonParsed.operation.includes("editMission")){
                    switch (jsonParsed.result) {
                        case 1:
                            setEditMission('');
                            setMissionEdited(true);
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        default :
                            break;
                    }
                }else {
                    if(jsonParsed.idMission>0){
                        localStorage.setItem("missionManagement", jsonParsed.idMission);
                        navigate("/manageMission");
                    } else if(jsonParsed.idMission==="-1"){
                        //display error -1 popup
                        setErrOneOpen(true);
                    } else if(jsonParsed.idMission==="-2"){
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                }
            });
        }
    },[source, idUser, mqttClient]);

    //check if the user has rights to add mission
    useEffect(() => {
        try{
            setAddMissionRights(idRight.includes("02112221623298373535"));
        } catch (error) {
            console.error(error);
            setAddMissionRights(false);
        }
    }, [idRight]);

    //go to the edit mission page
    const handleEdit = (mission) => {
        //set the user infos to recover it in the edit page
        localStorage.setItem("editMission", JSON.stringify(mission));
        setEditMission(mission.nameMission);
    }

    //go to manage mission page
    const handleManageMission = (mission) => {
        //set the user infos to recover it in the edit page
        localStorage.setItem("missionManagement", mission.idMission);
        navigate("/manageMission");
    }

    //display the correct status (the status 4 is not displayed)
    const displayState = (state) => {
        if(state === 1){
            return "Planned";
        }
        if(state === 2){
            return "in progress";
        }
        return "finished";
    }

    //display the name of the previous mission from the ID
    const displayPreviousMission = (previous) => {
        let displayed = "";
        missions.forEach((element) => {
            if(element.idMission === previous){
                displayed = element.nameMission;
            }
        })
        if(displayed === ""){
            return "unknown";
        }else {
            return displayed;
        }
    }

    return(
        <div className="baseMainDiv">
            <h2 className="baseTitle">All Missions</h2>
            {unknownError?
                <p>Sorry, an error has occurred</p>
                :
                <div className="baseDivAccordion">
                    <Accordion alwaysOpen>
                        {missions.sort((a,b) => a.nameMission > b.nameMission ? 1 : -1,).map(function (mission, id) {
                            return (
                                mission.stateMission !==4 ?
                                    <div key={id}>
                                        <Accordion.Item eventKey={id}>
                                            <Accordion.Header>{mission.nameMission}</Accordion.Header>
                                            <Accordion.Body className="baseAccordionBody">
                                            <table className="baseTableNoBorder">
                                                <tbody>
                                                <tr className="baseTr">
                                                    <td className="missionBoldSpan">Name:</td>
                                                    <td className="baseTd">{mission.nameMission}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="missionBoldSpan">Description:</td>
                                                    <td className="baseTd">{mission.descrMission !== "" ? mission.descrMission : "-"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="missionBoldSpan">Previous Mission:</td>
                                                    <td className="baseTd">{mission.previousMission !== mission.idMission ? displayPreviousMission(mission.previousMission) : "-"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="missionBoldSpan">Start date:</td>
                                                    <td className="baseTd">{mission.dateStart !== 0 ? moment(new Date(mission.dateStart*1000)).format("MMMM DD YYYY") : "Not defined yet"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="missionBoldSpan">Stop date:</td>
                                                    <td className="baseTd">{mission.dateStop !== 0 ? moment(new Date(mission.dateStop*1000)).format("MMMM DD YYYY") : "Not defined yet"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="missionBoldSpan">State:</td>
                                                    <td className="baseTd">{displayState(mission.stateMission)}</td>
                                                </tr>


                                                <tr>
                                                    <td className="deviceBoldSpan"></td>
                                                    <td>
                                                        {(mission.userRights === 0 || mission.userRights === 1) && (mission.stateMission === 1 || mission.stateMission === 2)?
                                                            <button className="missionButton" onClick={() => handleEdit(mission)}>Edit</button>
                                                            :
                                                            null
                                                        }
                                                        {mission.userRights === 0 || mission.userRights === 1?
                                                            <button className="missionButton" onClick={() => handleManageMission(mission)}>Manage mission</button>
                                                            :
                                                            <button className="missionButton" onClick={() => handleManageMission(mission)}>Mission details</button>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {editMission === mission.nameMission ?
                                                <div className="deviceEdit">
                                                    <EditMission setEditMission={setEditMission} idUser={idUser}/>
                                                </div>
                                                :
                                                null
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                                    :
                                    null
                            )
                        })}

                    </Accordion>
                    {addMissionRights ?
                        <button className="missionAddButton" onClick={() => setMissionAdd(true)}>Add mission</button>
                        :
                        null
                    }
                    {missionAdd ?
                        <AddMission setMissionAdd={setMissionAdd} missions={missions}/>
                        :
                        null
                    }
                </div>
            }
            <Snackbar open={missionEdited} autoHideDuration={4000} onClose={() => setMissionEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Mission successfully edited</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Mission name already exist</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Mission successfully added</Alert>
            </Snackbar>
        </div>
    )
}

export default GetMissions;
import React, {useContext, useState} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {Alert, Snackbar} from "@mui/material";

//the page to add some users
function AddUser(props) {

    const setUserAdd = props.setUserAdd;

    //variables to display the popups
    const[successOpen, setSuccessOpen] = useState(false);
    const[errOneOpen, setErrOneOpen] = useState(false);
    const[errTwoOpen, setErrTwoOpen] = useState(false);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        username: "",
        password: "",
        confirmPassword: "",
        firstname: "",
        lastname: "",
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        username: Yup.string().required("Required"),
        password: Yup.string()
            .required("Required")
            //min 8 characters
            .min(8, "Password must have at least 8 characters")
            //contains digits, lowercase and uppercase character
            .matches(/\d/, ">our password must have at least 1 digit character")
            .matches(/[A-Z]/, ">our password must have at least 1 uppercase character")
            .matches(/[a-z]/, ">our password must have at least 1 lowercase character"),
        confirmPassword: Yup.string().required("Please re-type your password")
            //oneOf is to match one of the values inside the array
            //ref is to get the value of the password
            .oneOf([Yup.ref("password")], "passwords does not match"),
        firstname: Yup.string().required("Required"),
        lastname: Yup.string().required("Required"),
    });

    //method to communicate with mqtt
    const submitMethod = (value, {resetForm}) => {

        //generate operation code
        let operation = mqttRequests.generateOperationCode("addUser");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "username":"' +
            value.username + '", "password":"' + value.password + '", "firstName":"' + value.firstname + '", "lastName":"' + value.lastname + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addUser");

        //publish a demand to add a user
        mqttRequests.publish(mqttClient, "user/addUser", data);

        resetForm({});

        operation = mqttRequests.generateOperationCode("getUsers");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getUsers");
        mqttRequests.publish(mqttClient, "user/getUsers", data);
    };

    return(
        <div className="missionDivAdd">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Username: " name="username"/>
                            <FormikControl control="input" type="password" label="Password: " name="password"/>
                            <p className="userTextPassword">Must contain at least 1 uppercase, 1 lowercase and 1 numeric character. Minimum 8 characters.</p>
                            <FormikControl control="input" type="password" label="Repeat password: " name="confirmPassword"/>
                            <FormikControl control="input" type="text" label="firstname: " name="firstname"/>
                            <FormikControl control="input" type="text" label="lastname: " name="lastname"/>
                            <button type="submit" className="UserButton">Validate</button>
                            <button type="button" className="UserButton" onClick={() => setUserAdd(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AddUser;
import React, {useContext, useEffect, useState} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {
    FormControl,
    Select,
    OutlinedInput,
    InputLabel,
    MenuItem,
    Checkbox,
    ListItemText,
    Snackbar,
    Alert, checkboxClasses,
} from "@mui/material";
import deleteButton from "../../assets/delete.png";

//the page witch display all the users in a group
function GetUserGroupUser(props) {

    const setEditGroup = props.setEditGroup;
    const groupUser = props.groupUser;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    const nameGroupUser = localStorage.getItem("groupUserName");

    //set variable to re get the data
    const [reshow, setReshow] = useState(false);
    //all the usernames of the user not in the group
    const [newUsernames, setNewUsernames] = useState(props.newUsernames);
    //username to add in the group
    const [addUsernames, setAddUsernames] = useState([]);
    //variable to set if the form is visible or not
    const [formVisible, setFormVisible] = useState(false);

    //variables to display the popups
    const[errEmptyOpen, setErrEmptyOpen] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operationusersInGroup = mqttRequests.generateOperationCode("getGroupUserUser");

            //create json to publish
            const dataRights = '{"operation":"' + operationusersInGroup + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupUser":"' + nameGroupUser + '"}';

            //subscribe to the channel to wait for the mqtt response (get all the users for the group)
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUserUser");

            //publish a demand to get all the user groups
            mqttRequests.publish(mqttClient, "user/getGroupUserUser", dataRights);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, reshow]);

    //manage the variables and display the form to add users in the group
    const handleDisplayForm = () => {
        //from the array with all users, keep only those not already in the group
        let tempUsernames = newUsernames.filter( (element) => !groupUser.includes(element));
        setNewUsernames(tempUsernames);

        //if no one left to add show the warning, else, show the form
        if(tempUsernames.length === 0){
            setErrEmptyOpen(true);
        }else {
            setFormVisible(true);
        }
    }

    //manage the changes in the form
    const handleChange = (event) => {
        const {
            target : {value},
        } = event;
        setAddUsernames(
            typeof value === 'string' ? value.split(',') : value,
           );
    };

    //add users in the group
    const handleAddButton = () => {
        if(addUsernames.length!==0){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("addGroupUserUser");

            //format data for json
            let loginToInsert = addUsernames.map(element => '"' + element + '"');

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "loginName":[' + loginToInsert + '], "idAccount":"' +
                idAccount + '", "nameGroupUser":"' + nameGroupUser + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupUserUser");

            //publish a demand to add a user in the group
            mqttRequests.publish(mqttClient, "user/addGroupUserUser", data);

            //hide the form and empty it
            setFormVisible(false);
            setAddUsernames([]);

            //reshow data
            setReshow(!reshow);

            //get again the groups user
            operation = mqttRequests.generateOperationCode("getGroupUser");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");
            mqttRequests.publish(mqttClient, "user/getGroupUser", data);
        }
    }

    //remove a user from a group
    const handleDelete = (username) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("removeGroupUserUser");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupUser":"' +
            nameGroupUser + '", "loginName":"' + username + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/removeGroupUserUser");

        //publish a demand to remove a user from the group
        mqttRequests.publish(mqttClient, "user/removeGroupUserUser", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);

        //get again the groups user
        operation = mqttRequests.generateOperationCode("getGroupUser");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");
        mqttRequests.publish(mqttClient, "user/getGroupUser", data);
    }

    return(
        <div>
            <table className="baseTableNoBorder">
                <tbody>
                {groupUser.sort((a,b) => a > b ? 1 : -1,).map(function (username, id) {
                    return (
                        <tr className="baseTr" key={id}>
                            <td className="alarmBoldSpan"></td>
                            <td className="baseTdFlex">
                                <span>{username}</span>
                                <img src={deleteButton} alt="X" className="deviceDeleteButton" onClick={() => handleDelete(username)}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <div>
                {!formVisible?
                    <>
                        <button className="alarmButtonManage" onClick={handleDisplayForm}>Add users to group</button>
                        <button className="alarmButton" onClick={() => setEditGroup('')}>Cancel</button>
                    </>
                    :
                    <div className="userFormControl">
                        <FormControl sx={{width: 1}}>
                            <InputLabel id="testMultipleCheckbox" className="deviceInputLabel">Select users</InputLabel>
                            <Select
                                id="test"
                                multiple
                                value={addUsernames}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select users" sx={{color:'#fff'}}/>}
                                renderValue={(selected) => selected.join(', ')}
                                className="deviceSelect"
                                inputProps={{MenuProps:{PaperProps:{sx:{backgroundColor:'#aaa'}}}}}
                            >
                                {newUsernames.sort((a,b) => a > b ? 1 : -1,).map((username) => (
                                    <MenuItem key={username} value={username} className="deviceMenuItem">
                                        <Checkbox checked={addUsernames.indexOf(username) > -1} sx={{[`&.${checkboxClasses.checked}`]: {color:'#000'}}}/>
                                        <ListItemText primary={username} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br/>
                        <button onClick={handleAddButton} className="UserButton">Validate</button>
                        <button onClick={() => setFormVisible(false)} className="UserButton">Cancel</button>
                    </div>
                }

            </div>
            <Snackbar open={errEmptyOpen} autoHideDuration={4000} onClose={() => setErrEmptyOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">All users are already in this group</Alert>
            </Snackbar>
        </div>
    )
}

export default GetUserGroupUser;
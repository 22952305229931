import React, {useEffect, useState} from 'react';
import {
    Routes,
    Route, BrowserRouter, Navigate,
} from "react-router-dom";
import {MqttClientContext} from "../context/MqttClientContext";
import {UserInfoContext} from "../context/UserInfoContext";
import mqtt from 'mqtt/dist/mqtt';

import Home from "./Home/Home";
import Menu from "../Utils/Menu";
import Login from "./Users/Login";
import mqttRequests from "../Utils/mqttRequests";
import GetGroupUser from "./Users/GetGroupUser";
import GetUsers from "./Users/GetUsers";
import DeviceList from "./Devices/DeviceList";
import GetGroupDevice from "./Devices/GetGroupDevice";
import GetKinetics from "./Kinetic/GetKinetics";
import DeviceData from "./Devices/DeviceData";
import GetMissions from "./Mission/GetMissions";
import ManageMissions from "./Mission/ManageMission";
import TypeNotification from "./Alarms/TypeNotification";
import AddMissionSupervision from "./Mission/AddMissionSupervision";
import GetAllSupervisions from "./Alarms/GetAllSupervisions";
import {SettingsContext} from "../context/SettingsContext";
import Settings from "../Utils/Settings";
import ProfileData from "./Devices/ProfileData";
import GetAlarms from "./Alarms/GetAlarms";
import GroupDeviceData from "./Devices/GroupDeviceData";
import KineticsData from "./Mission/KineticsData";
import GroupDataBattery from "./Devices/GroupDataBattery";
import "./Router.css";
import ParametersModifier from "./Devices/ParametersModifier";

//function managing all the renders with react router
function Router() {

    //variable for the coloration of the menu when a user click on a link
    const [menuClicked, setMenuClicked] = useState("");

    //the mqtt client (for the context)
    const [mqttClient, setMqttClient] = useState(null);

    //the id user (for the context)
    const [idUser, setIdUser] = useState(()=>{
        if(localStorage.getItem("idUser") !== null){
            return localStorage.getItem("idUser");
        }
        return "";
    });

    //the temperature unit for the context
    const [temperatureUnit, setTemperatureUnit] = useState(() => {
        if(localStorage.getItem("temperatureUnit") !== null){
            return localStorage.getItem("temperatureUnit");
        }
        return "C";
    });

    //the id account (for the context)
    const [idAccount, setIdAccount] = useState(localStorage.getItem("idAccount"));

    //the mqtt id rights (for the context)
    const [idRight, setIdRight] = useState(JSON.parse(localStorage.getItem("idRight")));

    //the source used to communicate with mqtt (for the context)
    const [mqttSource, setMqttSource] = useState(null);

    //variable used to regenerate a source when a user logout. If not --> 2 login and the same source
    const [refreshSource, setRefreshSource] = useState(false);

    const [userManagementRight, setUserManagementRight] = useState(true);

    //connect to the client when the page opens
    useEffect(() => {

        //create the source by add the actual time and a random big number (10 digits) to the 'web' keyword
        let sourceBase = Date.now();
        let sourceAdd = Math.floor(Math.random() * 9000000000) + 1;
        let source = "web" + sourceBase + sourceAdd;

        //set variable source for the context
        setMqttSource(source);

        //connect to mqtt and stock the client to a temp variable
        let tempMqttClient = mqtt.connect(process.env.REACT_APP_SERVER_PATH, {
            clean: true,
            reconnectPeriod : 1,
            username : process.env.REACT_APP_SERVER_USERNAME,
            password : process.env.REACT_APP_SERVER_PASSWORD,
            source : source,
        });
        
        //start to listen for messages
        mqttRequests.startListenMessage(tempMqttClient);

        //set the client for the context
        setMqttClient(tempMqttClient);

    }, [refreshSource]);

    //connect to the client when the page opens
    useEffect(() => {
        if(mqttClient){
            //check if the user is connected
            mqttClient.on("connect", () => {
            });

            mqttClient.on("error", (err) => {
                console.error('error: ' + err);
                mqttClient.end();
            });

        }
    }, [mqttClient]);

    //check if the user has rights
    useEffect(() => {
        if(idUser !== ""){
            if(idRight !== null){
                try{
                    setUserManagementRight(idRight.includes("02112221623298373532"));
                } catch (error) {
                    console.error(error);
                    setUserManagementRight(false);
                }
            }
        }
    }, [idRight, idUser]);

    return(
        <div className="routerMainDiv">
                <MqttClientContext.Provider value={{mqttClient: mqttClient, source: mqttSource}}>
                    <UserInfoContext.Provider value={{idUser: idUser, idAccount: idAccount, idRight: idRight}}>
                        <SettingsContext.Provider value={{temperatureUnit: temperatureUnit}}>
                            <BrowserRouter>
                                <div>
                                    <Menu menuClicked={menuClicked} setMenuClicked={setMenuClicked}
                                          setIdUser={setIdUser} setIdAccount={ setIdAccount} setIdRight={setIdRight}
                                          refreshSource={refreshSource} setRefreshSource={setRefreshSource}/>
                                </div>
                                <div className="RouterContent">
                                    <Routes>
                                        <Route exact path="/" element={<Home setMenuClicked={setMenuClicked} setIdUser={setIdUser} setIdAccount={ setIdAccount} setIdRight={setIdRight}
                                                                             refreshSource={refreshSource} setRefreshSource={setRefreshSource}/>}/>
                                        {idUser === ""?
                                            <Route path="/login" element={<Login setMenuClicked={setMenuClicked}
                                                                                 setIdUser={setIdUser} setIdAccount={ setIdAccount} setIdRight={setIdRight}/>}/>
                                            :
                                            null
                                        }
                                        {idAccount !== "" ?
                                            <>
                                                {userManagementRight?
                                                    <>
                                                        <Route path="/groupManagement" element={<GetGroupUser setMenuClicked={setMenuClicked}/>}/>
                                                        <Route path="/userManagement" element={<GetUsers setMenuClicked={setMenuClicked}/>}/>
                                                    </>
                                                    :
                                                    null
                                                }
                                                <Route path="/deviceList" element={<DeviceList setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/deviceData" element={<DeviceData setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/getGroupDevice" element={<GetGroupDevice setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/viewGroupData" element={<GroupDeviceData setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/viewGroupDataB" element={<GroupDataBattery setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/getKinetics" element={<GetKinetics setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/missions" element={<GetMissions setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/manageMission" element={<ManageMissions setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/recipient" element={<TypeNotification setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/addSupervision" element={<AddMissionSupervision setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/supervision" element={<GetAllSupervisions setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/settings" element={<Settings setTemperatureUnit={setTemperatureUnit} setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/profileData" element={<ProfileData setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/alarms" element={<GetAlarms setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/kineticsData" element={<KineticsData setMenuClicked={setMenuClicked}/>}/>
                                                <Route path="/parameters" element={<ParametersModifier setMenuClicked={setMenuClicked}/>}/>
                                            </>
                                            :
                                            null
                                        }
                                        <Route path="*" element={<Navigate to={"/"}/>}/>
                                    </Routes>
                                </div>
                            </BrowserRouter>
                        </SettingsContext.Provider>
                    </UserInfoContext.Provider>
                </MqttClientContext.Provider>
        </div>
    )
}

export default Router;
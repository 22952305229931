import React, {useContext} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";

//the page to add some groupNotifications (recipients)
//groupNotification = recipient group
function AddGroupNotification(props) {

    const setAddGroupRecipient = props.setAddGroupRecipient;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        name: "",
        description: "",
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
    });

    //method to add new recipient
    const submitMethod = (value, {resetForm}) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("addGroupNotification");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupNotif":"' + value.name + '", "descrGroupNotif":"' +
            value.description + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupNotification");
        //publish a demand to add a groupNotification
        mqttRequests.publish(mqttClient, "alarm/addGroupNotification", data);

        //to get the recipient groups
        operation = mqttRequests.generateOperationCode("getGroupNotification");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
        mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);
    };

    return(
        <div className="alarmAdd">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Group name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="alarmButton">Validate</button>
                            <button type="button" className="alarmButton" onClick={() => setAddGroupRecipient(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AddGroupNotification;
import React, {useContext, useEffect, useState} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import {Alert, Dialog, DialogActions, DialogTitle, Snackbar} from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import ChangePassword from "./ChangePassword";

//the page witch display all the users of an account
function GetUsers(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('userManagement');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/")
            setMenuClicked("home");
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //set variable to get the data
    const [users, setUsers] = useState([]);
    //set variable to display an error message if something goes wrong
    const [unknownError, setUnknownError] = useState(false);
    //set variable to re get the data
    const [reshow, setReshow] = useState(false);
    //set variable for the dialog (message "are you sure to hide")
    const[dialogOpen, setDialogOpen] = useState(false);
    //set variable to store the username
    const [username, setUsername] = useState("");
    //set variable for the action Hide/show/active/deactive
    const [action, setAction] = useState("");

    //set variable to display the popup in case of password modification
    const [passwordChanged, setPasswordChanged] = useState(false);
    //set variable to display the popup in case of user edit
    const [userEdited, setUserEdited] = useState(false);
    //variables to display the popups
    const[errOneOpen, setErrOneOpen] = useState(false);
    const[errTwoOpen, setErrTwoOpen] = useState(false);
    const[errThreeOpen, setErrThreeOpen] = useState(false);
    const[errThreeOpenPassword, setErrThreeOpenPassword] = useState(false);
    const[errFourOpen, setErrFourOpen] = useState(false);
    const[errFiveOpen, setErrFiveOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errOneOpenAdd, setErrOneOpenAdd] = useState(false);

    const [editUser, setEditUser] = useState('');
    const [userAdd, setUserAdd] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getUsers");

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getUsers");

            //publish a demand to get all the users
            mqttRequests.publish(mqttClient, "user/getUsers", data);


            //read incoming message and send response to a function to refactor them
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //if it has login name, it's to load data
                if(jsonParsed.userLoginName){
                    if(jsonParsed.result===1){
                        refactorData(jsonParsed);
                    }else{
                        setUnknownError(true);
                    }
                }else if(jsonParsed.operation.includes("editUser")){
                    switch (jsonParsed.result) {
                        case 1:
                            setUserEdited(true);
                            setEditUser('')
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        case -3:
                            //display error popup
                            setErrThreeOpen(true);
                            break;
                        default :
                            break;
                    }
                }else if(jsonParsed.operation.includes("changePassword")){
                    switch (jsonParsed.result) {
                        case 1 :
                            //display the success popup
                            setPasswordChanged(true);
                            setEditUser('');
                            break;
                        case -1 :
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2 :
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        case -3 :
                            //display error popup
                            setErrThreeOpenPassword(true);
                            break;
                        case -4 :
                            //display error popup
                            setErrFourOpen(true);
                            break;
                        case -5 :
                            //display error popup
                            setErrFiveOpen(true);
                            break;
                        default :
                            break;
                    }
                }else if(jsonParsed.operation.includes("addUser")){
                    if(jsonParsed.idUser>0){
                        //display success popup
                        setSuccessOpen(true);
                        setUserAdd(false);
                    } else {
                        console.error('error : ' + jsonParsed.idUser);
                        if(jsonParsed.idUser==="-1"){
                            //display error -1 popup
                            setErrOneOpenAdd(true);
                        } else if(jsonParsed.idUser==="-2"){
                            //display error -2 popup
                            setErrTwoOpen(true);
                        }
                    }
                }
            })
        }
    },[source, idAccount, mqttClient , reshow]);

    //transform 5 separates arrays in 1 object with all the values to make it easier to render
    const refactorData = (data) => {
        setUsers(data.userLoginName.map((id, index_value) =>{
            return {
                login: data.userLoginName[index_value],
                firstname: data.userFirstName[index_value],
                lastname: data.userLastName[index_value],
                active: data.userActif[index_value],
                hide: data.userHide[index_value],
            }
        }));
    };

    //open the dialog "Are you sure you want to do that ?"
    const handleClickHideActive = (action, username) => {
        //set the action and the username
        setAction(action);
        setUsername(username);

        //display the dialog
        setDialogOpen(true);
    }

    //manage the hide, show, activate and deactivate a user
    const hideActive = () => {
        //close the confirmation dialog
        setDialogOpen(false);

        //correct the action to use the correct name to interact with mqtt (subscribe and post in the right channel)
        let realAction = action + "User";

        //generate operation code
        const operation = mqttRequests.generateOperationCode(realAction);

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "username":"' + username + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/" + realAction);

        //publish a demand to get hide/show/activate/deactivate a user
        mqttRequests.publish(mqttClient, "user/" + realAction, data);

        //recharge data
        setReshow(!reshow);
    }

    //go to the edit user page
    const handleEdit = (user) => {
        //set the user infos to recover it in the edit page
        localStorage.setItem("username", user.login);
        localStorage.setItem("firstname", user.firstname);
        localStorage.setItem("lastname", user.lastname);
        setEditUser(user.login);
    }

    //go to the edit user page
    const handleChangePassword = (user) => {
        //set the username info to recover it in the change password page
        localStorage.setItem("username", user.login);
        setEditUser('password' + user.login);
    }
    return(
        <div className="userMainDiv">
            <h2 className="UserTitle">Users</h2>
            {unknownError?
                <p>Sorry, an error has occurred</p>
                :
                <div className="userDivAccordion">
                    <Accordion alwaysOpen>
                        {users.sort((a,b) => a.login > b.login ? 1 : -1,).map(function (user, id) {
                            return (
                                <div key={id}>
                                    <Accordion.Item eventKey={id}>
                                        <Accordion.Header>{user.login}</Accordion.Header>
                                        <Accordion.Body className="userAccordionBody">
                                            <table className="userTableNoBorder">
                                                <tbody>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">User:</td>
                                                    <td className="baseTd">{user.login}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Firstname:</td>
                                                    <td className="baseTd">{user.firstname}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Lastname:</td>
                                                    <td className="baseTd">{user.lastname}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Active:</td>
                                                    <td className="baseTd">{user.active === 1? "Yes" : "No"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Hide:</td>
                                                    <td className="baseTd">{user.hide === 1? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="deviceBoldSpan"></td>
                                                    <td>
                                                        <button className="UserButton" onClick={() => handleEdit(user)}>Edit</button>
                                                        <button className="UserButton" onClick={() => handleChangePassword(user)}>Change password</button>
                                                        {user.hide===0?
                                                            <button className="UserButton" onClick={() => handleClickHideActive("hide", user.login)}>Hide user</button>
                                                            :
                                                            <button className="UserButton" onClick={() => handleClickHideActive("show", user.login)}>Show user</button>
                                                        }
                                                        {user.active===1?
                                                            <button className="UserButton" onClick={() => handleClickHideActive("deactivate", user.login)}>Deactivate user</button>
                                                            :
                                                            <button className="UserButton" onClick={() => handleClickHideActive("activate", user.login)}>Activate user</button>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {editUser === user.login ?
                                                <div className="deviceEdit">
                                                    <EditUser setEditUser={setEditUser}/>
                                                </div>
                                                :
                                                null
                                            }
                                            {editUser === 'password' + user.login ?
                                                <div className="deviceEdit">
                                                    <ChangePassword setEditUser={setEditUser}/>
                                                </div>
                                                :
                                                null
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                            )
                        })}
                    </Accordion>
                    <button className="deviceButtonAdd" onClick={() => setUserAdd(true)}>Add user</button>
                    {userAdd ?
                        <AddUser setUserAdd={setUserAdd}/>
                        :
                        null
                    }
                </div>
            }
            <Snackbar open={passwordChanged} autoHideDuration={4000} onClose={() => setPasswordChanged(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Password successfully changed</Alert>
            </Snackbar>
            <Snackbar open={userEdited} autoHideDuration={4000} onClose={() => setUserEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">User successfully edited</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - user not found, try again later</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={errThreeOpen} autoHideDuration={4000} onClose={() => setErrThreeOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - new username already exist</Alert>
            </Snackbar>
            <Snackbar open={errThreeOpenPassword} autoHideDuration={4000} onClose={() => setErrThreeOpenPassword(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - The new password is the same as an old password</Alert>
            </Snackbar>
            <Snackbar open={errFourOpen} autoHideDuration={4000} onClose={() => setErrFourOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Wrong actual password (non-existent)</Alert>
            </Snackbar>
            <Snackbar open={errFiveOpen} autoHideDuration={4000} onClose={() => setErrFiveOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Wrong actual password (non-active)</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">User successfully added</Alert>
            </Snackbar>
            <Snackbar open={errOneOpenAdd} autoHideDuration={4000} onClose={() => setErrOneOpenAdd(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - username already exist</Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure to " + action + " this user ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="UserButton" onClick={() => setDialogOpen(false)}>No</button>
                    <button className="UserButton" onClick={hideActive}>Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GetUsers;
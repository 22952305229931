//stock for incoming messages
let messageCallback = () => { }

//start to listening for arriving message, when a message is received, stock it to messageCallback
function startListenMessage (mqttClient){
    mqttClient.on('message', (topic, message) => messageCallback(message.toString(), topic))
}

//function witch give the message when asked
function incomingMessage(callback) {
    messageCallback = callback
}

//generic function to publish data to mqtt, need a client, a source, some data and a quality of service(optional)
function publish(mqttClient, source, data, qos=1, retained=false) {
    mqttClient.publish(source, data, {qos: qos, retain: retained});
}

//generic function to subscribe to a topic, need a client, a source and a quality of service(optional)
function subscribe(mqttClient, source, qos=1) {
    mqttClient.subscribe(source, qos, (error) => {
        if (error) {
            console.error('Subscribe to topics error : ' + error);
        }
    });
}

//generic function to unsubscribe to a topic, need a client, a source and a quality of service(optional)
function unSubscribe(mqttClient, source) {
    mqttClient.unsubscribe(source, (error) => {
        if (error) {
            console.error('unsubscription error : ' + error);
        }
    });
}

//generate an operation number by adding the actual time and a random big number (10 digits) to the given keyword
function generateOperationCode(operationName){
    let operationBase = Date.now();
    let operationAdd = Math.floor(Math.random() * 90000) + 1;
    return operationName + operationBase + operationAdd;
}

//give access to function across the app
const mqttRequest = {
    startListenMessage,
    incomingMessage,
    publish,
    subscribe,
    unSubscribe,
    generateOperationCode,
}

export default mqttRequest
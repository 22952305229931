import React, {useContext, useEffect, useState} from 'react';
import './Mission.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Form, Formik, useFormikContext} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SettingsContext} from "../../context/SettingsContext";

//the page to add some missions supervisions
function AddMissionSupervision() {

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            navigate("/manageMission")
        }, false);
    }, [navigate]);

    //variables to display the popup
    const[errTwoOpen, setErrTwoOpen] = useState(false);
    const [errSubDevice, setErrSubDevice] = useState(false);
    const [errKinGateway, setErrKinGateway] = useState(false);

    //if a kinetic is selected or not
    const [kineticSelected, setKineticSelected] = useState(false);
    //if a device is selected or not
    const [deviceSelected, setDeviceSelected] = useState(false);
    //if a kinetics have a warning or a critical and their values
    const [kineticsHaveWarning, setKineticsHaveWarning] = useState(false);
    const [kineticsHaveCritical, setKineticsHaveCritical] = useState(false);
    const [kineticsWarningValue, setKineticsWarningValue] = useState("");
    const [kineticsCriticalValue, setKineticsCriticalValue] = useState("");

    const [hasSubDevice, setHasSubDevice] = useState(false);
    const [subDeviceModified, setSubDeviceModified] = useState(false);
    const [identifierDevice, setIdentifierDevice] = useState("");
    const [idModelDevice, setIdModelDevice] = useState("");
    const [subDeviceOptions, setSubDeviceOptions] = useState(()=>{
        return(
            [
                {key:"Select", value: ""},
                {key:"Gateway", value: "gateway"},
            ]
        )
    })

    //history for observer
    const [idKineticsHistory, setIdKineticsHistory] = useState("");
    const [idDeviceHistory, setIdDeviceHistory] = useState("");
    const [limitTypeHistory, setLimitTypeHistory] = useState("");
    const [subDeviceHistory, setSubDeviceHistory] = useState("");

    //if limitType = 3
    const [limitType3, setLimitType3] = useState(false);
    const [crashSelected, setCrashSelected] = useState(false);

    //list kinetics, devices, kinetics and groupNotifications in the mission
    const [devices, setDevices] = useState([]);
    const [devicesFromGroup, setDevicesFromGroup] = useState([]);
    const [kinetics, setKinetics] = useState(JSON.parse(localStorage.getItem("kineticsMission")));
    const [groupNotification, setGroupNotification] = useState([])
    //the content of the select devices
    const [devicesSelectOptions, setDevicesSelectOptions] = useState([]);
    //the datatype and if datatype is displayed or not
    const [dataType, setDataType] = useState([]);
    const [dataTypeActif, setDataTypeActif] = useState([]);
    //the id of the mission
    const idMission = localStorage.getItem("idMission");
    //the devices and the kinetics
    const allDevices = JSON.parse(localStorage.getItem("devices"));
    const allKinetics = JSON.parse(localStorage.getItem("kinetics"));
    //the kinetic selected
    const [valueKineticSelected, setValueKineticSelected] = useState(null);

    //the idUser and idAccount for mqtt
    const {idUser, idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the temperature unit from the context
    const {temperatureUnit} = useContext(SettingsContext);

    //the initial values for formik
    const initialValues = {
        idKinetics: "",
        idGroupNotification: "",
        idDevice: "",
        subDevice: "",
        idDataType: "",
        severity: 1,
        limit: 0,
        limitType: 1,
    };

    //the yup validation schema
    const validationSchema = Yup.object().shape({
        idKinetics: Yup.string().notRequired(),
        idGroupNotification: Yup.string().required("Required"),
        idDevice: Yup.string().required("Required"),
        idDataType: Yup.string().when("limitType", {
            is: 3,
            then: () => Yup.string().notRequired(),
            otherwise: () => Yup.string().when("idKinetics", {
                is: undefined,
                then : () => Yup.string().required("Required"),
                otherwise:  () => Yup.string().notRequired(),
        })}),

        limit: Yup.number().required("required"),
        limitType: Yup.number().required("Required"),
    });

    //the options for the select severity
    const severityOptions = [
        {key:"Warning", value: 1},
        {key:"Critical", value: 2},
    ]

    //the options for the select limitType
    const limitTypeOptions = [
        {key:"Bigger than", value: 1},
        {key:"Lower than", value: 2},
        {key:"No more points", value: 3},
    ]

    //get infos for the selects from devices + devices in groupDevices, kinetics, groupNotifications and datatype
    useEffect(() => {
        if(mqttClient !== null){
            let groupDeviceMission = JSON.parse(localStorage.getItem("groupDeviceMission"));
            let tempDevices = [];
            let tempDeviceFromGroup = [];
            //if it's a device, add it and if it's a group, select all devices in it
            groupDeviceMission.forEach((element)=> {
                if(element.idDevice !== "null"){
                    tempDevices.push({key: getDeviceIdentifierFromId(element.idDevice), value: element.idDevice})
                }
                //find every device in the groups
                if(element.idGroupDevice !== "null"){
                    //generate operation code
                    const operation = mqttRequests.generateOperationCode("getGroupDeviceDeviceFromId");
                    //create json to publish
                    const data = '{"operation":"' + operation + '", "source":"' + source + '", "idGroupDevice":"' + element.idGroupDevice + '"}';

                    //subscribe to the channel to wait for the mqtt response
                    mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDeviceDeviceFromId");
                    //publish a demand to add a mission
                    mqttRequests.publish(mqttClient, "device/getGroupDeviceDeviceFromId", data);
                }
            })
            //set the devices
            setDevices(tempDevices);

            //read incoming message
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //response for getGroupDeviceDeviceFromId, add the device from group into the variable
                if(jsonParsed.idDevice){
                    jsonParsed.idDevice.forEach((element)=>{
                        tempDeviceFromGroup.push({key: getDeviceIdentifierFromId(element), value: element})
                    });
                    setDevicesFromGroup(tempDeviceFromGroup);
                //response for getGroupNotification
                }else if(jsonParsed.groupNotification){
                    let defineGroupNotification = [];
                    jsonParsed.groupNotification.forEach((element) => {
                        defineGroupNotification.push({key: element.nameGroupNotif, value: element.idGroupNotification})
                    })
                    setGroupNotification([{key: "Select", value: ""}, ...defineGroupNotification])
                //response for getDataType
                }else if(jsonParsed.idDataType){
                    refactorDataType(jsonParsed);
                //response for addMissionSupervision
                }else if(jsonParsed.result && !jsonParsed.kineticsMissions){
                    if(jsonParsed.result === 1){
                        localStorage.setItem("missionSupervisionAdded", "true");
                        navigate("/manageMission");
                    }else {
                        setErrTwoOpen(true);
                    }
                }else if(jsonParsed.data){
                    handleTeltonikaSubDevice(jsonParsed.data);
                }
            })
            //manage the kinetics options
            let tempKinetics = kinetics.map((element)=> {
                return {key: getKineticsIdentifierFromId(element.idKinetics), value: element.idKinetics}
            })
            setKinetics([{key: "None", value: ""}, ...tempKinetics]);

            //get group notifications
            let operation = mqttRequests.generateOperationCode("getGroupNotification");
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
            mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);

            //get the dataTypes
            operation = mqttRequests.generateOperationCode("getDataType");
            data = '{"operation":"' + operation + '", "source":"' + source + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getDataType");
            mqttRequests.publish(mqttClient, "device/getDataType", data);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mqttClient, source]);
    //set the subDevices for teltonika
    useEffect(()=>{
        if(hasSubDevice === true){
            //get points from the device to have the name of the subdevices
            let operation = mqttRequests.generateOperationCode("dataAsked");
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "identifier":"' + identifierDevice + '", "from":"' + Math.round(new Date().getTime()/1000) + '", "to":"' + Math.round(new Date().getTime()/1000) + '", "idModel":"' + idModelDevice + '", "dataType":["20221222161657076001"], "sortType":0}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/dataAsked");
            mqttRequests.publish(mqttClient, "data/dataAsked", data);
        }
    }, [subDeviceModified, hasSubDevice, identifierDevice, mqttClient, source, idModelDevice])

    //mix the devices and the devices from group
    useEffect(() => {
        if(devices.length !== 0 || devicesFromGroup.length !== 0){
            let tempDevicesSelectOptions = [...devices, ...devicesFromGroup];
            setDevicesSelectOptions([{key: "Select", value: ""}, ...getUnique(tempDevicesSelectOptions)]);
        }
    }, [devices, devicesFromGroup]);

    //set values critical and warning for the kinetic when selected
    useEffect(()=> {
        if(valueKineticSelected !== null){
            setKineticsHaveWarning(false);
            setKineticsHaveCritical(false);
            valueKineticSelected.kinParameterKinetics.forEach((element) => {
                if(element.idKinParameter === "20230210153546656001" && element.valueString !== ""){
                    setKineticsHaveCritical(true);
                    setKineticsCriticalValue(element.valueString);
                }
                if(element.idKinParameter === "20230210153551170002" && element.valueString !== ""){
                    setKineticsHaveWarning(true);
                    setKineticsWarningValue(element.valueString);
                }
            })
        }
    }, [valueKineticSelected]);

    //set the teltonika subDevices
    const handleTeltonikaSubDevice = (data) => {
        let tempOptions = [];
        tempOptions.push({key: "Select", value: ""}) ;
        tempOptions.push({key: "Gateway", value: "gateway"}) ;

        Object.keys(data).filter(name => name.includes("Temperature")).forEach((element)=> {
            tempOptions.push({key: element.substring(13), value: element.substring(13)});
        })
        tempOptions = tempOptions.filter(name => name.value !== "0");

        setSubDeviceOptions(tempOptions);
    }

    //refactor the dataType to set the datatype with the correct key value
    const refactorDataType = (data) => {
        let tempOptionsDataType = data.idDataType.map((element, id) => {
            return(
                {
                    key : data.description[id],
                    value : element
                }
            )
        })
        setDataType(tempOptionsDataType);
    }

    //function to remove redundancy on a list in 1 index (taken from stackOverflow : 37217953)
    const getUnique = (arr) => {
        return arr.map(e => e["value"])

            .map((e,i,final)=> final.indexOf(e) === i && i)

            .filter(e => arr[e]).map(e=>arr[e]);
    }

    //get the device identifier from the id
    const getDeviceIdentifierFromId = (id) => {
        let response = "";
        allDevices.forEach((element) => {
            if(element.idDevice === id){
                response = element.identifier
            }
        })
        return response;
    }
    //get the kinetics name from the id
    const getKineticsIdentifierFromId = (id) => {
        let response = "";
        allKinetics.forEach((element) => {
            if(element.idKinetics === id){
                response = element.nameKinetics
            }
        })
        return response;
    }
    //method to add a missionSupervision in mqtt
    const submitMethod = (value) => {
        if(hasSubDevice && value.subDevice === "" && value.limitType !== '3'){
            setErrSubDevice(true);
        }else if(value.idKinetics !== "" && value.subDevice === "gateway"){
            setErrKinGateway(true);
        }else {
            if((kineticSelected && kineticsHaveWarning) || (kineticSelected && kineticsHaveCritical) || !kineticSelected){
                //generate operation code
                const operation = mqttRequests.generateOperationCode("addMissionSupervision");

                let datatype = value.idDataType;
                let severity = value.severity;
                let limitType = value.limitType;
                let limit = value.limit;

                console.log(limitType)
                if (limitType === "3" && limit === 0){
                    limit = 1;
                }
                if (limitType === "3"){
                    datatype = '20230126103350301001';
                }

                //set the temperature in Celsius
                if(datatype === "20221222161657076001" && (limitType === "1" || limitType === "2")){
                    if(temperatureUnit === "F"){
                        limit = Math.round(((value.limit-32)*5/9)*100)/100;
                    }
                    if(temperatureUnit === "K"){
                        limit = Math.round((value.limit-273.15)*100)/100;
                    }
                }

                if(crashSelected){
                    limitType = 1;
                    limit = 0;
                }
                //if a kinetic is selected, set the datatype and the limit to match with the kinetic
                if(kineticSelected){
                    datatype = "20230126103350301003";
                    if(KineticLargerOrLower() === "Larger than"){
                        limitType = 1;
                    } else {
                        limitType = 2;
                    }

                    if(kineticsHaveCritical){
                        severity = 2;
                        limit = kineticsCriticalValue;
                    }else {
                        severity = 1;
                        limit = kineticsWarningValue;
                    }

                    //if the kinetic has both critical and warning, add warning in addition to the critical add (2 supervisions)
                    if(kineticsHaveCritical && kineticsHaveWarning){
                        //create json to publish
                        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '", "idMission":"' + idMission + '", "idKinetics":"' +
                            value.idKinetics + '", "idGroupNotification":"' + value.idGroupNotification + '", "idDevice":"' + value.idDevice + '", "idDatatype":"' + datatype
                            + '", "severity":"' + 1 + '", "limite":"' + kineticsWarningValue + '", "limiteType":"' + limitType + '", "actif":"1", "gatewayIdentifier":"' + value.subDevice + '"}';
                        //subscribe to the channel to wait for the mqtt response
                        mqttRequests.subscribe(mqttClient, "source/" + source + "/addMissionSupervision");
                        //publish a demand to add a supervision
                        mqttRequests.publish(mqttClient, "alarm/addMissionSupervision", data);

                    }
                }
                let subDevice = value.subDevice ;
                //to set battery for teltonika
                if(value.subDevice === "gateway"){
                    if(value.idDataType === "Mv"){
                        datatype = "20230126103350301001";
                        subDevice = "mv";
                    }
                    if(value.idDataType === "external"){
                        datatype = "20230126103350301001";
                        subDevice = "external";
                    }
                }

                //create json to publish
                const data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '", "idMission":"' + idMission + '", "idKinetics":"' +
                    value.idKinetics + '", "idGroupNotification":"' + value.idGroupNotification + '", "idDevice":"' + value.idDevice + '", "idDatatype":"' + datatype
                    + '", "severity":"' + severity + '", "limite":"' + limit + '", "limiteType":"' + limitType + '", "actif":"1", "gatewayIdentifier":"' + subDevice + '"}';
                //subscribe to the channel to wait for the mqtt response
                mqttRequests.subscribe(mqttClient, "source/" + source + "/addMissionSupervision");
                //publish a demand to add a supervision
                mqttRequests.publish(mqttClient, "alarm/addMissionSupervision", data);
            }
        }
    };
    //observer to trigger things when the form changes
    const FormObserver: React.FC = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            if(idKineticsHistory !== values.idKinetics){
                setIdKineticsHistory(values.idKinetics);
                if(values.idKinetics !== ""){
                    setKineticSelected(true);
                    allKinetics.forEach((element)=>{
                        if(element.idKinetics === values.idKinetics){
                            setValueKineticSelected(element);
                        }
                    });
                }else {
                    setKineticSelected(false);
                }
            }
            if(idDeviceHistory !== values.idDevice){
                setIdDeviceHistory(values.idDevice);
                if(values.idDevice !== ""){
                    setHasSubDevice(false);
                    if(values.idDevice !== ""){
                        setDeviceSelected(true);
                        //get the datatype of the device
                        let dataTypeId = [];
                        let teltonika = false;
                        devicesSelectOptions.forEach((element)=>{
                            if(element.value === values.idDevice){
                                allDevices.forEach((device) => {
                                    if(device.idDevice === element.value){
                                        dataTypeId = device.dataType;
                                        if(device.manufacturer === "Teltonika"){
                                            setHasSubDevice(true);
                                            setSubDeviceModified(!subDeviceModified);
                                            setIdentifierDevice(device.identifier);
                                            setIdModelDevice(device.idModel);
                                            teltonika = true;
                                        }
                                    }
                                })
                            }
                        });
                        //get a table of all active dataTypes to the select
                        let tempDataTypeActive = dataType.filter(data => dataTypeId.some(element => {return element === data.value}));
                        setDataTypeActif([{key:"Select", value: ""}, ...tempDataTypeActive]);
                        if(teltonika === true){
                            setDataTypeActif([{key:"Select", value: ""}]);
                        }
                    }
                } else {
                    setFieldValue("idDataType", "");
                    setDeviceSelected(false);
                }
            }
            if(limitTypeHistory !== values.limitType){
                setLimitTypeHistory(values.limitType);
                if(values.limitType === "3"){
                    setLimitType3(true);
                }else{
                    setLimitType3(false);
                }
            }
            if(subDeviceHistory !== values.subDevice){
                setSubDeviceHistory(values.subDevice);
                if(values.subDevice !== ""){
                    setFieldValue("idDataType", "");
                    if(values.subDevice === "gateway"){
                        setDataTypeActif([{key:"Select", value: ""}, {key: "Battery %", value: "20230126103350301001"}, {key: "Battery Mv", value: "Mv"},
                            {key: "Battery external", value: "external"}, {key: "Latitude", value: "20221222161814823007"},
                            {key: "Longitude", value: "20221222161808885005"}, {key: "Acceleration X", value: "20230126103350301004"},
                            {key: "Acceleration Y", value: "20230126103350301005"}, {key: "Acceleration Z", value: "20230126103350301006"}, {key: "Crash", value: "20230126103350301008"}]);
                    }else{
                        let dataTypeId = [];
                        allDevices.forEach((device) => {
                            if (device.identifier === values.subDevice) {
                                dataTypeId = device.dataType;
                            }
                        });
                        let tempDataTypeActive = dataType.filter(data => dataTypeId.some(element => {return element === data.value}));
                        setDataTypeActif([{key:"Select", value: ""}, ...tempDataTypeActive]);
                    }
                }else {
                    setDataTypeActif([{key: "Select", value: ""}]) ;
                }
            }
            //if crash selected -> hide limitType and limit
            if(values.idDataType === '20230126103350301008'){
                setCrashSelected(true);
            }else {
                setCrashSelected(false);
            }
        }, [values, setFieldValue]);
        return null;
    }
    //set if for the kinetic selected, the limitType is larger or lower
    const KineticLargerOrLower = () => {
        let valueEnd = 0;
        let valueStart = 0;

        valueKineticSelected.kinParameterKinetics.forEach((element) => {
            if(element.idKinParameter === "20230210153653274025"){
                valueEnd = element.arrayValueFloat;
            }
            if(element.idKinParameter === "20230210153649071024"){
                valueStart = element.arrayValueFloat;
            }
        })
        if(valueStart<valueEnd){
            return "Larger than";
        }else {
            return "Lower than";
        }
    }

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormObserver/>
                            <h2 className="baseTitle">Plan a supervision</h2>
                            <FormikControl control="select" label="Device: " name="idDevice" options={devicesSelectOptions}/>
                            {hasSubDevice && !limitType3 ?
                                <FormikControl control="select" label="Device connected: " name="subDevice" options={subDeviceOptions}/>
                                :
                                null
                            }
                            {!limitType3?
                                <FormikControl control="select" label="Kinetics: " name="idKinetics" options={kinetics}/>
                                :
                                null
                            }
                            <FormikControl control="select" label="Recipient group: " name="idGroupNotification" options={groupNotification}/>
                            {kineticSelected ?
                                !kineticsHaveWarning && !kineticsHaveCritical?
                                    <p className="missionRedText">If you select a kinetics, it must be one with warning or critical limits</p>
                                    :
                                    <>
                                        <FormikControl control="input" type="text" label="Data Type: " name="idDataType" value="reaction progress" disabled/>
                                        {kineticsHaveWarning ?
                                            <FormikControl control="input" type="text" label="Severity: " name="level" value="Warning" disabled/>
                                            :
                                            <FormikControl control="input" type="text" label="Severity: " name="level" value="Critical" disabled/>
                                        }
                                        <FormikControl control="input" type="text" label="Limit type: " name="limitType" value={KineticLargerOrLower()} disabled/>
                                        {kineticsHaveWarning ?
                                            <FormikControl control="input" type="number" label="Limit: " name="limit" value={kineticsWarningValue} disabled/>
                                            :
                                            <FormikControl control="input" type="number" label="Limit: " name="limit" value={kineticsCriticalValue} disabled/>
                                        }
                                        {kineticsHaveWarning && kineticsHaveCritical ?
                                            <p className="missionText">Two supervisions will be created, one for the warning and one for the critical level (critical limit is {kineticsCriticalValue})</p>
                                            :
                                            null
                                        }
                                    </>
                                :
                                <>
                                    {deviceSelected && !limitType3 ?
                                        <FormikControl control="select" label="Data Type: " name="idDataType" options={dataTypeActif}/>
                                        :
                                        null
                                    }
                                    <FormikControl control="select" label="Severity: " name="severity" options={severityOptions}/>
                                    {!crashSelected ?
                                        <>
                                            <FormikControl control="select" label="Limit type: " name="limitType" options={limitTypeOptions}/>
                                            {limitType3?
                                                <FormikControl control="input" type="number" label="Limit (time in minutes): " name="limit"/>
                                                :
                                                <FormikControl control="input" type="number" label="Limit: " name="limit"/>
                                            }
                                        </>
                                        :
                                        null
                                    }


                                </>
                            }
                            <button type="submit" className="missionSubmit">Add</button>
                        </Form>
                    )
                }}
            </Formik>
            <div className="missionBackButton">
                <button onClick={() => navigate("/manageMission")}>Back</button>
            </div>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={errSubDevice} autoHideDuration={4000} onClose={() => setErrSubDevice(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Select a device connected with your gateway</Alert>
            </Snackbar>
            <Snackbar open={errKinGateway} autoHideDuration={4000} onClose={() => setErrKinGateway(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - A kinetic alarm can not be set on the gateway</Alert>
            </Snackbar>
        </div>
    )
}

export default AddMissionSupervision;
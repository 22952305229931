import React, {useContext, useEffect, useState} from 'react';
import './Alarm.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {
    FormControl,
    Select,
    OutlinedInput,
    InputLabel,
    MenuItem,
    Checkbox,
    ListItemText,
    Snackbar,
    Alert, checkboxClasses,
} from "@mui/material";
import deleteButton from "../../assets/delete.png";

//the page witch display all the recipient in a group
//typeNotification = recipient, groupNotification = recipient group
function ManageGroupNotification(props) {

    const setEditTypeNotif = props.setEditTypeNotif;
    const typeNotification = props.typeNotification;
    const [groupTypeNotification, setGroupTypeNotification] = useState(props.groupTypeNotification);

    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the group to manage
    const groupNotification = JSON.parse(localStorage.getItem("groupNotificationManage"));

    //set variable to re get the data
    const [reshow, setReshow] = useState(false);
    //all the name of the typeNotifications not in the group
    const [newTypeNotification, setNewTypeNotification] = useState([]);
    //typeNotification to add in the group
    const [addTypeNotification, setAddTypeNotification] = useState([]);
    //variable to set if the form is visible or not
    const [formVisible, setFormVisible] = useState(false);

    //variables to display the popups
    const[errEmptyOpen, setErrEmptyOpen] = useState(false);

    useEffect(() => {
        let tempNotif = [];
        props.groupTypeNotification.forEach((notif) => {
            typeNotification.forEach((element) => {
                if (element.idTypeNotification === notif){
                    tempNotif.push(element.nameTypeNotif);
                }
            })
        })
        setGroupTypeNotification(tempNotif);
    }, [props.groupTypeNotification]);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //to get the typeNotifications in the group
            let operation = mqttRequests.generateOperationCode("getGroupTypeNotification");
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idGroupNotification":"' + groupNotification.idGroupNotification + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupTypeNotification");
            mqttRequests.publish(mqttClient, "alarm/getGroupTypeNotification", data);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, reshow]);

    //manage the variables and display the form to add typeNotifications in the group
    const handleDisplayForm = () => {

        let tempName = [];
        groupTypeNotification.forEach((name) => {
            typeNotification.forEach((element) => {
                if (element.nameTypeNotif === name){
                    tempName.push(element.idTypeNotification);
                }
            })
        })

        //from the array with all type notifications, keep only those not already in the group
        let tempTypeNotification = typeNotification.filter( (element) => !tempName.includes(element.idTypeNotification));
        setNewTypeNotification(tempTypeNotification);

        //if no one left to add show the warning, else, show the form
        if(tempTypeNotification.length === 0){
            setErrEmptyOpen(true);
        }else {
            setFormVisible(true);
        }
    }

    //manage the changes in the form
    const handleChange = (event) => {
        const {
            target : {value},
        } = event;
        setAddTypeNotification(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    //add recipients in the group
    const handleAddButton = () => {
        if(addTypeNotification.length!==0){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("addGroupTypeNotification");

            //find the id with the given name
            let idForName = addTypeNotification.map((element) => {
                let id = "";
                typeNotification.forEach((typeNotif) => {
                    if(typeNotif.nameTypeNotif === element){
                        id = typeNotif.idTypeNotification;
                    }
                })
                return id;
            })
            //format data for json
            let typeNotificationToInsert = idForName.map(element => '"' + element + '"');

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idTypeNotification":[' + typeNotificationToInsert + '], "idGroupNotification":"' +
                groupNotification.idGroupNotification + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupTypeNotification");
            //publish a demand to add a recipient
            mqttRequests.publish(mqttClient, "alarm/addGroupTypeNotification", data);

            //hide the form and empty it
            setFormVisible(false);
            setAddTypeNotification([]);

            //rerender the data
            setReshow(!reshow);

            //to get the recipient groups
            operation = mqttRequests.generateOperationCode("getGroupNotification");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
            mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);
        }
    }

    //remove a recipient from a group
    const handleDelete = (name) => {

        let idTypeNotification
        typeNotification.forEach((element) => {
            if (element.nameTypeNotif === name){
                idTypeNotification = element.idTypeNotification;
            }
        })

        //generate operation code
        let operation = mqttRequests.generateOperationCode("deleteGroupTypeNotification");
        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idTypeNotification":"' + idTypeNotification + '", "idGroupNotification":"' +
            groupNotification.idGroupNotification + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/deleteGroupTypeNotification");
        //publish a demand to add a recipient
        mqttRequests.publish(mqttClient, "alarm/deleteGroupTypeNotification", data);

        //rerender the data
        setReshow(!reshow);

        //rerender the main
        operation = mqttRequests.generateOperationCode("getGroupNotification");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupNotification");
        mqttRequests.publish(mqttClient, "alarm/getGroupNotification", data);
    }

    return(
        <div>
            <table className="baseTableNoBorder">
                <tbody>
                {groupTypeNotification.sort((a,b) => a > b ? 1 : -1,).map(function (element, id) {
                    return (
                        <tr className="baseTr" key={id}>
                            <td className="alarmBoldSpan"></td>
                            <td className="baseTdFlex">
                                <span>{element}</span>
                                <img src={deleteButton} alt="X" className="deviceDeleteButton" onClick={() => handleDelete(element)}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div>
                {!formVisible?
                    <>
                        <button className="alarmButtonManage" onClick={handleDisplayForm}>Add recipient to group</button>
                        <button className="alarmButton" onClick={() => setEditTypeNotif('')}>Cancel</button>
                    </>
                    :
                    <div className="alarmFormControl">
                        <FormControl sx={{width: 1}}>
                            <InputLabel id="testMultipleCheckbox" className="deviceInputLabel">Select recipient</InputLabel>
                            <Select
                                id="test"
                                multiple
                                value={addTypeNotification}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select type Notification" sx={{color:'#fff'}}/>}
                                renderValue={(selected) => selected.join(', ')}
                                className="deviceSelect"
                                inputProps={{MenuProps:{PaperProps:{sx:{backgroundColor:'#aaa'}}}}}
                            >
                                {newTypeNotification.sort((a,b) => a.nameTypeNotif > b.nameTypeNotif ? 1 : -1,).map((element) => (
                                    <MenuItem key={element.idTypeNotification} value={element.nameTypeNotif} className="deviceMenuItem">
                                        <Checkbox checked={addTypeNotification.indexOf(element.nameTypeNotif) > -1} sx={{[`&.${checkboxClasses.checked}`]: {color:'#000'}}}/>
                                        <ListItemText primary={element.nameTypeNotif} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br/>
                        <button onClick={handleAddButton} className="alarmButton">Validate</button>
                        <button onClick={() => setFormVisible(false)} className="alarmButton">Cancel</button>
                    </div>
                }

            </div>
            <Snackbar open={errEmptyOpen} autoHideDuration={4000} onClose={() => setErrEmptyOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">All users are already in this group</Alert>
            </Snackbar>
        </div>
    )
}

export default ManageGroupNotification;
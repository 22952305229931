import React, {useContext} from 'react';
import './Devices.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";

//the page to add some device group
function AddGroupDevice(props) {

    let setAddGroupDevice = props.setAddGroupDevice ;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        name: "",
        description: "",
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
    });

    //method add group device
    const submitMethod = (value, {resetForm}) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("addGroupDevice");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "name":"' + value.name + '", "description":"' +
            value.description + '", "idAccount":"' + idAccount + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupDevice");

        //publish a demand to add a group
        mqttRequests.publish(mqttClient, "device/addGroupDevice", data);
        resetForm({});
        setAddGroupDevice(false);

        operation = mqttRequests.generateOperationCode("getGroupDevice");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.publish(mqttClient, "device/getGroupDevice", data);
    };

    return(
        <div className="deviceDivAdd">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Group name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="devicesButton">Validate</button>
                            <button type="button" className="devicesButton" onClick={() => setAddGroupDevice(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AddGroupDevice;